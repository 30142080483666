import React, { useState } from "react";
import { isEqual } from "lodash";
import FrameViewItem from "./FrameViewItem";
import { ISocialNetworkPostFileVM } from "../../../../utils/interfaces/IPost";
import { useFramesStore } from "../../../../store/framesStore";
import AgreementOfRemoveProgress from "./AgreementOfRemoveProgress";

function ImageSelectorItem({ image }: { image: ISocialNetworkPostFileVM }) {
  const {
    whichFrameIsRendering,
    setSelectedImage,
    selectedImage,
    setActiveRenderedFrame,
    activeRenderedFrame,
    activeFrame,
    setActiveFrame,
  } = useFramesStore();

  const [isAgreementOfRemoveProgressOpen, setIsAgreementOfRemoveProgressOpen] =
    useState<ISocialNetworkPostFileVM | null>(null);

  const handleImageClick = () => {
    if (!whichFrameIsRendering) {
      if (
        activeRenderedFrame &&
        (!isEqual(activeRenderedFrame.templateId, selectedImage?.templateId) ||
          !isEqual(
            activeRenderedFrame.tokens?.map((t) => ({
              name: t.name,
              value: t.value,
            })),
            selectedImage?.tokens?.map((t) => ({
              name: t.name,
              value: t.value,
            }))
          ))
      ) {
        setIsAgreementOfRemoveProgressOpen(image);
        return;
      }
      setActiveRenderedFrame(undefined);
      setActiveFrame(undefined);
      setSelectedImage(image);
    }
  };

  return (
    <>
      {isAgreementOfRemoveProgressOpen && (
        <AgreementOfRemoveProgress
          image={isAgreementOfRemoveProgressOpen}
          isOpen={!!isAgreementOfRemoveProgressOpen}
          setIsOpen={(p) => setIsAgreementOfRemoveProgressOpen(p)}
        />
      )}
      <div
        className={`w-[152px] min-h-[152px] relative aspect-square ${
          !whichFrameIsRendering ? "cursor-pointer" : "cursor-default"
        }`}
        onClick={handleImageClick}
      >
        <img
          className={`
        w-[124px] h-[124px]
        object-cover absolute top-0 left-0 rounded-[12px] `}
          src={
            image.thumbnailSmallUrl ||
            image?.template?.thumbnail ||
            activeFrame?.thumbnail
          }
          alt=""
        />
        <FrameViewItem
          isActive={selectedImage?.id === image.id}
          className="absolute bottom-0 right-0 w-[112px] h-[112px]"
          file={image}
        />
      </div>
    </>
  );
}

export default ImageSelectorItem;
