import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  ISinglePostVM,
  ISocialNetworkPostFileVM,
  ISocialNetworkPostScore,
  ISocialNetworkPostVM,
  IStockGroupVM,
  IStockImageVM,
} from "../utils/interfaces/IPost";
import { SocialNetworkType } from "../utils/enums/Enums";
import { ISocialNetworkVM } from "../utils/interfaces/ISocialNetworks";
import { IBaseData } from "../utils/interfaces/IBase";
import { ISingleFIleVM } from "../utils/interfaces/IAssets";
import { ICopilotMessage } from "../utils/copilot-base/ICopilotBase";
import { ISuggestedPrompt } from "../components/creation/hooks/useFillSuggestedPrompts";

export type CreationTabs =
  | "brief"
  | "media"
  | "elements"
  | "accounts"
  | "published";

export interface IContentStoreInitialInterface {
  postData?: ISinglePostVM;
  activeSoc: SocialNetworkType | null;
  activeTab: CreationTabs;
  postUpdating: boolean;
  briefMessages: ICopilotMessage[];
  copilotSessionId: string | null;
  activeSocialNetworkPost?: ISocialNetworkPostVM;
  selectedSocialNetworks: ISocialNetworkVM[];
  whichSocialNetworkTypeConnectIsLoading: null | SocialNetworkType;
  refetchConnections: boolean;
  isPublishing: null | "publishing" | "scheduling" | "planning";
  tabsOverlay: boolean;
  librarySearchValue: string;
  stocks: IStockImageVM[];
  stocksPage: number;
  isStocksFetching: boolean;
  stocksResponse?: IBaseData<IStockGroupVM>;
  defaultStockStory: string;
  isPostOpen: boolean;
  videoSelectionAgreementModalOpen: null | {
    file: ISingleFIleVM;
    forAll: boolean;
  };
  inAdvanceMedia: ISingleFIleVM[];
  postIdea?: string | null;
  postId?: number;
  improveIdea?: string | null;
  suggestedPrompts: ISuggestedPrompt[];
  isImageUploadingInChat: boolean;
  isMobileSectionsDrawerOpen: boolean;
  whichGifIsLoading: string | number | null;
  isSocialScoreLoading: boolean;
  socialScore?: ISocialNetworkPostScore;
  sessionErrored: boolean;
}

const initialState: IContentStoreInitialInterface = {
  activeSoc: null,
  postUpdating: false,
  postData: undefined,
  activeTab: "brief",
  briefMessages: [],
  copilotSessionId: null,
  activeSocialNetworkPost: undefined,
  selectedSocialNetworks: [],
  whichSocialNetworkTypeConnectIsLoading: null,
  refetchConnections: false,
  isPublishing: null,
  tabsOverlay: false,
  librarySearchValue: "",
  stocks: [],
  stocksPage: 1,
  isStocksFetching: false,
  stocksResponse: undefined,
  defaultStockStory: "black and white. cars",
  isPostOpen: false,
  videoSelectionAgreementModalOpen: null,
  inAdvanceMedia: [],
  postIdea: null,
  postId: undefined,
  improveIdea: null,
  suggestedPrompts: [],
  isImageUploadingInChat: false,
  isMobileSectionsDrawerOpen: true,
  whichGifIsLoading: null,
  isSocialScoreLoading: false,
  socialScore: undefined,
  sessionErrored: false,
};

const store = (set: any) => ({
  ...initialState,
  setBriefMessages: (briefMessages: ICopilotMessage[]) =>
    set({ briefMessages }),
  setSessionErrored: (sessionErrored: boolean) => set({ sessionErrored }),
  setActiveSoc: (activeSoc: SocialNetworkType) => set({ activeSoc }),
  setPostIdea: (postIdea: string | null | undefined) => set({ postIdea }),
  setImproveIdea: (improveIdea: string | null | undefined) =>
    set({ improveIdea }),
  setSuggestedPrompts: (suggestedPrompts: ISuggestedPrompt[]) =>
    set({ suggestedPrompts }),
  setWhichGifIsLoading: (whichGifIsLoading: string | number | null) =>
    set({ whichGifIsLoading }),
  setPostId: (postId: number | undefined) => set({ postId }),
  setVideoSelectionAgreementModalOpen: (
    videoSelectionAgreementModalOpen: null | {
      file: ISingleFIleVM;
      forAll: boolean;
    }
  ) => set({ videoSelectionAgreementModalOpen }),
  setIsPostOpen: (isPostOpen: boolean) => set({ isPostOpen }),
  setInAdvanceMedia: (inAdvanceMedia: ISingleFIleVM[]) =>
    set({ inAdvanceMedia }),
  setIsStocksFetching: (isStocksFetching: boolean) => set({ isStocksFetching }),
  setStocksResponse: (stocksResponse: IBaseData<IStockGroupVM> | undefined) =>
    set({ stocksResponse }),
  setStocksPage: (stocksPage: number) => set({ stocksPage }),
  setStocks: (stocks: IStockImageVM[]) => set({ stocks }),
  setLibrarySearchValue: (librarySearchValue: string) =>
    set({ librarySearchValue }),
  setTabsOverlay: (tabsOverlay: boolean) => set({ tabsOverlay }),
  setRefetchConnections: (refetchConnections: boolean) =>
    set({ refetchConnections }),
  setWhichSocialNetworkTypeConnectIsLoading: (
    whichSocialNetworkTypeConnectIsLoading: SocialNetworkType | null
  ) => set({ whichSocialNetworkTypeConnectIsLoading }),
  setPostUpdating: (postUpdating: boolean) => set({ postUpdating }),
  setIsPublishing: (
    isPublishing: null | "publishing" | "scheduling" | "planning"
  ) => set({ isPublishing }),
  setCopilotSessionId: (copilotSessionId: string | null) =>
    set({ copilotSessionId }),
  setActiveTab: (activeTab: CreationTabs) => set({ activeTab }),
  setSelectedSocialNetworks: (selectedSocialNetworks: ISocialNetworkVM[]) =>
    set({ selectedSocialNetworks }),
  setPostData: (postData: ISinglePostVM) => set({ postData }),
  setActiveSocialNetworkPost: (activeSocialNetworkPost: ISocialNetworkPostVM) =>
    set({ activeSocialNetworkPost }),
  setIsImageUploadingInChat: (isImageUploadingInChat: boolean) =>
    set({ isImageUploadingInChat }),
  setIsMobileSectionsDrawerOpen: (isMobileSectionsDrawerOpen: boolean) =>
    set({ isMobileSectionsDrawerOpen }),
  setIsSocialScoreLoading: (isSocialScoreLoading: boolean) =>
    set({ isSocialScoreLoading }),
  setSocialScore: (socialScore: undefined | ISocialNetworkPostScore) =>
    set({ socialScore }),
  reset: () => set(initialState),
});
export const useCreationStore = create(devtools(store));
