import React, { useMemo } from "react";
import { ISingleBrandVM } from "../../utils/interfaces/IBrand";
import { IBaseData } from "../../utils/interfaces/IBase";
import SRadio from "../../design-system/SRadio";
import SButton from "../../design-system/SButton";
import {
  faCircle,
  faPlus,
  faRefresh,
} from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { useGetAllBrands } from "../../api/brand/getAll";
import { usePutSwitchBrand } from "../../api/brand/switch/put";
import { OrganizationType } from "../../utils/enums/Enums";
import { Radio } from "@headlessui/react";

function BrandSelector({
  setIsBrandSelectorOpen,
  currentBrand,
  setIsMobileMenuOpen,
}: {
  setIsBrandSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentBrand: IBaseData<ISingleBrandVM>;
  setIsMobileMenuOpen?: (open: boolean) => void;
}) {
  const navigate = useNavigate();
  const { mutateAsync: switchBrand, isPending } = usePutSwitchBrand();
  const { data: brands } = useGetAllBrands({ includeAllWorkspaces: false });

  const sortedBrands = useMemo(() => {
    if (!brands || !brands.data) return [];

    const currentBrandItem = brands.data.find(
      (item) => item.id === currentBrand.data.id
    );
    const otherBrands = brands.data.filter(
      (item) => item.id !== currentBrand.data.id
    );

    return currentBrandItem ? [currentBrandItem, ...otherBrands] : otherBrands;
  }, [brands, currentBrand.data.id]);

  return (
    <div
      className={`
      ${brands ? (brands.data.length > 1 ? "" : "px-0") : "px-0"} 
      flex flex-col items-center w-[450%] max-md:w-full absolute top-[100%] left-0  bg-base-white px-[8px] rounded-b-[12px] rounded-tr-[12px] shadow-lg
      `}
    >
      <div className="w-full h-[1px] bg-gray-200 my-[10px]" />
      <div className="flex flex-col w-full gap-[10px] max-h-[300px] overflow-auto">
        {sortedBrands &&
          sortedBrands.map((item) => (
            <div
              onClick={() => {
                if (item.id) {
                  switchBrand({ id: item.id });
                  setIsMobileMenuOpen && setIsMobileMenuOpen(false);
                }
              }}
              className={`bg-gray-25 border border-gray-200 py-[8px] px-[10px] rounded-[8px] flex justify-between items-center cursor-pointer`}
            >
              <div className="flex items-center gap-[10px] overflow-hidden">
                <img
                  className="max-w-[32px] min-w-[32px] w-full max-h-[32px] object-contain rounded-[6px]"
                  src={item.secondLogo ? item.secondLogo : item.logo}
                  alt="logo"
                />
                <div>
                  <p className="text-xs text-gray-400">
                    {item.organizationType === OrganizationType.COMPANY &&
                      "Company"}
                    {item.organizationType === OrganizationType.PERSONAL &&
                      "Personal"}
                  </p>
                  <p
                    className="max-md:w-full text-sm-sb text-gray-700"
                    title={item.name}
                  >
                    {item.name}
                  </p>
                </div>
              </div>
              <SRadio
                size="sm"
                disabled={isPending}
                checked={item.id === currentBrand.data.id}
              />
            </div>
          ))}
      </div>
      <SButton
        onClick={() => {
          setIsBrandSelectorOpen(false);
          setIsMobileMenuOpen && setIsMobileMenuOpen(false);
          navigate("/create-brand");
        }}
        type="secondaryGray"
        rIcon={faPlus}
        size="sm"
        className={`
           ${sortedBrands ? (sortedBrands.length >= 1 ? "mt-[18px] mb-[18px]" : "mt-0 mb-[8px]") : "mt-[18px] mb-[18px]"} 
          `}
      >
        Create Brand
      </SButton>
    </div>
  );
}

export default BrandSelector;
