import React from "react";

const ContentSkeleton = ({ className }: { className?: string }) => {
  return (
    <div
      className={`${className} w-full relative p-[24px] mt-[18px] bg-gray-100 animate-pulse rounded-[12px]`}
    >
      <div className="flex justify-between items-center">
        <div>
          <div className="flex justify-between items-center rounded-[12px] w-[120px] h-[16px] bg-gray-400" />
          <div className="flex justify-between items-center rounded-[12px] w-[60px] h-[8px] bg-gray-300 mt-[4px]" />
        </div>
        <div className="flex justify-between items-center rounded-[12px] w-[20px] h-[20px] bg-gray-300 mt-[4px]" />
      </div>
      <div className="w-full h-[1px] bg-gray-300 my-[24px]" />
      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center rounded-[12px] w-[120px] h-[16px] bg-gray-400" />
        <div className="flex justify-between items-center rounded-[12px] w-[20px] h-[20px] bg-gray-300 mt-[4px]" />
      </div>
    </div>
  );
};

export default ContentSkeleton;
