import { useState } from "react";
import { useGetPostIdeas } from "../../../api/brand/postIdeas/get";
import { useGetWindowWidth } from "../../../hooks/useGetWindowWidth";
import { useCreationHelperFunctions } from "../../creation/hooks/useCreationHelperFunctions";

export const useContentIdeas = () => {
  const { data: postIdeas, isFetching } = useGetPostIdeas();
  const { handleOpenPost } = useCreationHelperFunctions();
  const [viewAll, setViewAll] = useState(false);
  const { windowWidth } = useGetWindowWidth();

  const getVisiblePostIdeaCount = () => {
    if (windowWidth > 1200) return 5;
    if (viewAll) return postIdeas?.data.length;
    if (windowWidth <= 880) return 2;
    if (windowWidth <= 1000) return 4;
    return 5;
  };

  return {
    postIdeas,
    setViewAll,
    getVisiblePostIdeaCount,
    handleOpenPost,
    viewAll,
    windowWidth,
    isFetching,
  };
};
