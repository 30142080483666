import {
  faFaceFrown,
  faFaceMeh,
  faFaceSmile,
} from "@fortawesome/pro-regular-svg-icons";
import { useState, useEffect, useRef } from "react";
import { useCreationStore } from "../../../store/creationStore";
import { useHandleOutsideClick } from "../../../hooks/useHandleOutsideClick";

export const useRenderSocialScore = () => {
  const {
    socialScore,
    isSocialScoreLoading,
    setImproveIdea,
    briefMessages,
    activeTab,
    setActiveTab,
  } = useCreationStore();
  const [openMessage, setOpenMessage] = useState(false);
  const [copy, setCopy] = useState(false);
  const socialScoreRef = useRef<HTMLDivElement>(null);
  useHandleOutsideClick({
    ref: socialScoreRef,
    callBack: () => setOpenMessage(false),
    excludedIds: ["social-scoring"],
  });

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  }, [copy]);
  const colors: any = ["red", "warning", "success"];
  const icons: any = [faFaceFrown, faFaceMeh, faFaceSmile];
  const color = socialScore
    ? colors[Math.floor((socialScore.score / 11) * colors.length)]
    : colors[0];
  const icon = socialScore
    ? icons[Math.floor((socialScore.score / 11) * icons.length)]
    : icons[0];

  const isMessaging =
    briefMessages.length > 0
      ? !briefMessages[briefMessages.length - 1].content
      : true;
  return {
    socialScore,
    isSocialScoreLoading,
    openMessage,
    setOpenMessage,
    color,
    icon,
    setCopy,
    copy,
    setImproveIdea,
    isMessaging,
    activeTab,
    setActiveTab,
    socialScoreRef,
  };
};
