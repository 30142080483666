import {
  faCalendarDay,
  faCreditCard,
  faGear,
  faGrid2,
  faImage,
  faLink,
  faPaintBrush,
  faShareNodes,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCalendarDay as faSolidCalendarDay,
  faCreditCard as faSolidCreditCard,
  faGear as faSolidGear,
  faGrid2 as faSolidGrid2,
  faImage as faSolidImage,
  faLink as faSolidLink,
  faPaintBrush as faSolidPaintBrush,
  faShareNodes as faSolidShareNodes,
  faMegaphone as faSolidMegaphone,
} from "@fortawesome/pro-solid-svg-icons";
import NavigationGroup from "./NavigationGroup";
import { faMegaphone } from "@fortawesome/pro-regular-svg-icons/faMegaphone";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useAccountStore } from "../../store/accountStore";
import {
  brandSubMenu,
  calendarSubMenu,
  contentSubMenu,
  workspaceSubMenu,
} from "../../utils/content";

function Navigation({
  setIsMobileMenuOpen,
}: {
  setIsMobileMenuOpen?: (open: boolean) => void;
}) {
  const accountStore = useAccountStore();
  const handleClick = () => {
    setIsMobileMenuOpen && setIsMobileMenuOpen(false);
  };

  return (
    <div className="mx-[12px] mt-[24px]">
      <div className="flex flex-col gap-[16px]">
        <NavigationGroup
          pages={[
            // {
            //   title: "Dashboard",
            //   icon: faGrid2,
            //   solidIcon: faSolidGrid2,
            //   path: "/",
            //   pathTitle: "dashboard",
            //   handleClick: () => handleClick(),
            // },
            {
              title: "Content",
              icon: faMegaphone,
              solidIcon: faSolidMegaphone,
              path: "/",
              pathTitle: "content",
              handleClick: () => handleClick(),
              subMenuItems: contentSubMenu,
            },
            {
              title: "Brand",
              icon: faPaintBrush,
              solidIcon: faSolidPaintBrush,
              path: "/brand?section=brandbook",
              pathTitle: "brand",
              handleClick: () => handleClick(),
              subMenuItems: brandSubMenu,
            },

            {
              title: "Assets",
              icon: faImage,
              solidIcon: faSolidImage,
              path: "/assets",
              pathTitle: "assets",
              handleClick: () => handleClick(),
            },
            {
              title: "Calendar",
              icon: faCalendarDay,
              solidIcon: faSolidCalendarDay,
              path: "/calendar?section=month",
              pathTitle: "calendar",
              handleClick: () => handleClick(),
              subMenuItems: calendarSubMenu,
            },
            {
              title: "Accounts",
              icon: faShareNodes,
              solidIcon: faSolidShareNodes,
              path: "/brand?section=accounts",
              pathTitle: "accounts",
              handleClick: () => handleClick(),
            },
          ]}
        />
        <NavigationGroup
          className="border-t border-gray-200 pt-[12px]"
          pages={[
            {
              title: "Settings",
              icon: faGear,
              solidIcon: faSolidGear,
              path: "/workspace?section=overview",
              pathTitle: "workspace",
              handleClick: () => handleClick(),
              subMenuDirection: "up",
              subMenuItems: workspaceSubMenu,
            },
            ...(accountStore.personalInfo?.userRole ===
            OrganizationUserRole.VIEWER
              ? []
              : [
                  {
                    title: "Billing",
                    icon: faCreditCard,
                    solidIcon: faSolidCreditCard,
                    path: "/workspace?section=billing",
                    pathTitle: "billing",
                    handleClick: () => handleClick(),
                  },
                ]),
          ]}
        />
      </div>
    </div>
  );
}

export default Navigation;
