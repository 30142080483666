import React from "react";
import SCheckbox from "../../../../design-system/SCheckbox";
import { ISocialNetworkVM } from "../../../../utils/interfaces/ISocialNetworks";
import LettersAvatar from "../../../utils/LettersAvatar";
import { Tooltip } from "react-tooltip";
import { PostStatus } from "../../../../utils/enums/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

interface ITableTrItemChannel {
  isDisabled: boolean;
  page: ISocialNetworkVM;
  isChecked: boolean;
  handleSelect: (socNetwork: ISocialNetworkVM) => void;
  dataTooltipId?: string;
  status: PostStatus;
}

function TableTrItemChannel({
  isDisabled,
  status,
  isChecked,
  handleSelect,
  dataTooltipId,
  page,
}: ITableTrItemChannel) {
  const initial = page?.name ? page.name.charAt(0).toUpperCase() : "";

  return (
    <>
      <div
        data-tooltip-id={dataTooltipId}
        onClick={() => !isDisabled && handleSelect(page)}
        className={`flex items-center gap-[12px] px-[16px] rounded-[8px] cursor-pointer
          py-[8px] w-fit border-[1px] border-graymodern-300 bg-base-white *:
          ${isDisabled ? "opacity-50 !cursor-default" : ""}
          `}
      >
        {!isDisabled && <SCheckbox checked={isChecked} />}
        {isDisabled && (
          <FontAwesomeIcon
            icon={faCheck}
            className="text-lg text-success-500"
          />
        )}
        {page?.logoUrl ? (
          <img
            src={page.logoUrl}
            alt="logo"
            className="w-[28px] h-[28px] rounded-full"
          />
        ) : (
          <LettersAvatar
            text={page?.name}
            className="!w-[28px] !h-[28px] text-xs-m"
          />
        )}
        <p className="text-sm-m text-gray-900 break-all">{page?.name}</p>
      </div>
    </>
  );
}

export default TableTrItemChannel;
