import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContentIdeas } from "../../content/hooks/useContentIdeas";
import { faSparkles } from "@fortawesome/pro-regular-svg-icons";
import SRadio from "../../../design-system/SRadio";
import { useState } from "react";
import { SuggestionSkeleton } from "./SuggestionSkeleton";

export interface IEmptyContentSuggestionsProps {
  setValue: (value: string) => void;
}

export const EmptyContentSuggestions = ({
  setValue,
}: IEmptyContentSuggestionsProps) => {
  const { postIdeas, isFetching } = useContentIdeas();
  const [selected, setSelected] = useState<number | null>(null);

  return (
    <div className="w-full h-full flex flex-col max-sm-m:mt-0 gap-4">
      <div className="flex-grow"></div>{" "}
      {postIdeas && postIdeas.data.length > 0 && (
        <div className="flex items-center gap-3">
          <FontAwesomeIcon icon={faSparkles} className="text-brand-500" />
          <p className="text-md-b xl:text-xl text-gray-800">Post Ideas</p>
        </div>
      )}
      <div className="flex flex-col gap-2">
        {!isFetching ? (
          postIdeas &&
          postIdeas.data.length > 0 &&
          postIdeas?.data
            .map((item) => {
              return (
                <label
                  key={item.id}
                  onClick={() => {
                    setValue(item.idea);
                    setSelected(item.id);
                  }}
                  htmlFor={`suggestion_button_${item.id}`}
                  className={`cursor-pointer flex gap-3 items-start border-2 ${selected === item.id ? "bg-base-white border-brand-500" : "bg-brand-50 hover:bg-gray-50 "} transition-all p-3 rounded-lg`}
                >
                  <SRadio
                    name="suggestion_button"
                    id={`suggestion_button_${item.id}`}
                    size="sm"
                  />
                  <p className="text-sm xl:text-md text-gray-600">
                    {item.idea}
                  </p>
                </label>
              );
            })
            .splice(0, 4)
        ) : (
          <>
            <SuggestionSkeleton />
            <SuggestionSkeleton />
            <SuggestionSkeleton />
            <SuggestionSkeleton />
          </>
        )}
      </div>
    </div>
  );
};
