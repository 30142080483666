import React, { useEffect, useState } from "react";

import PreviewSide from "../components/creation/previews/PreviewSide";
import SButton from "../design-system/SButton";
import {
  faCloud,
  faCloudArrowUp,
  faComment,
  faCopy,
  faDownload,
  faSave,
  faShare,
  faShareAll,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import CreationHeader from "../components/creation/CreationHeader";
import Brief from "../components/creation/Tabs/Brief";
import { useInitializePost } from "../components/creation/hooks/useInitializePost";
import { useCreationStore } from "../store/creationStore";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
import Channels from "../components/creation/Tabs/Channels";
import Media from "../components/creation/Tabs/Media";
import { useCreationLibraryHelperFunctions } from "../components/creation/Tabs/Media/hooks/useCreationLibraryHelperFunctions";
import { useCreationHelperFunctions } from "../components/creation/hooks/useCreationHelperFunctions";
import { OrganizationUserRole, PostStatus } from "../utils/enums/Enums";
import CommentsModal from "../components/creation/CommentsModal";
import VideoSelectionAgreement from "../components/creation/modals/VideoSelectionAgreement";
import PublishedTab from "../components/creation/Tabs/PublishedTab";
import { getQuery } from "../hooks/functions/queries";
import Frames from "../components/creation/Tabs/Frames";
import { useAccountStore } from "../store/accountStore";
import SpeechRecognition from "react-speech-recognition";
import { useGetWindowWidth } from "../hooks/useGetWindowWidth";
import MobileSectionsDrawer from "../components/creation/MobileSectionsDrawer";
import { Tooltip } from "react-tooltip";
import { toast } from "../hooks/toast";
import { useSocialScore } from "../components/creation/hooks/useSocialScore";
import { useUpdatePost } from "../components/creation/hooks/useUpdatePost";
import { useFillSuggestedPrompts } from "../components/creation/hooks/useFillSuggestedPrompts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SessionErrored from "../components/creation/Tabs/Brief/SessionErrored";

const Creation = ({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  useInitializePost();
  const {
    activeTab,
    setActiveTab,
    activeSoc,
    socialScore,
    sessionErrored,
    postUpdating,
    tabsOverlay,
    setSocialScore,
    postData,
    isSocialScoreLoading,
    setIsMobileSectionsDrawerOpen,
  } = useCreationStore();
  useLockBodyScroll(isOpen);
  const {
    isPostPublishedOrScheduled,
    getActiveSocialNetworkPost,
    handleDeleteEmptyPost,
  } = useCreationHelperFunctions();
  const { updateSocialScoreIntoPost } = useUpdatePost();
  useEffect(() => {
    if (isPostPublishedOrScheduled()) {
      setActiveTab("accounts");
    }
  }, [postData, activeSoc]);

  useEffect(() => {
    if (activeTab === "accounts") {
      setIsMobileSectionsDrawerOpen(false);
    }
  }, [isOpen]);

  const { personalInfo } = useAccountStore();
  const [isUpdatedByCopilot, setIsUpdatedByCopilot] = useState(false);

  const { windowWidth } = useGetWindowWidth();

  const handleClose = async () => {
    await handleDeleteEmptyPost();
    SpeechRecognition.stopListening();
    onClose();
  };
  const { appendSocialScore } = useSocialScore();

  useEffect(() => {
    if (getActiveSocialNetworkPost() && !socialScore && !isSocialScoreLoading) {
      if (getActiveSocialNetworkPost()!.socialScore) {
        setSocialScore(getActiveSocialNetworkPost()!.socialScore);
      }
    }
  }, [getActiveSocialNetworkPost()]);

  useEffect(() => {
    if (useCreationStore.getState().socialScore && !isSocialScoreLoading) {
      if (
        useCreationStore.getState().socialScore?.socialNetworkType !==
        useCreationStore.getState().activeSoc
      ) {
        appendSocialScore();
      }
    }
  }, [activeSoc]);

  useEffect(() => {
    if (socialScore) {
      updateSocialScoreIntoPost();
    }
  }, [socialScore]);

  const [showPostUpdating, setShowPostUpdating] = useState(false);

  useEffect(() => {
    if (!showPostUpdating && postUpdating) {
      setShowPostUpdating(true);
    }
  }, [postUpdating]);

  useEffect(() => {
    if (showPostUpdating) {
      setTimeout(() => {
        setShowPostUpdating(false);
      }, 1000);
    }
  }, [showPostUpdating]);

  return (
    <>
      <VideoSelectionAgreement />
      <Drawer
        open={isOpen}
        onClose={async () => {
          await handleClose();
        }}
        direction="bottom"
        className="min-h-[97dvh] rounded-[24px] max-md:rounded-b-[0px]"
        lockBackgroundScroll={true}
      >
        <div className="flex gap-[58px] absolute top-[20px] right-[20px] z-[1000] max-md:top-[90px] max-md:right-[unset] max-md:left-[20px] max-md:!z-[10]">
          <div className="flex gap-[6px] max-md:flex-row-reverse">
            <Tooltip id="post-last-state-saved">All changes saved</Tooltip>
            <div
              data-tooltip-id="post-last-state-saved"
              className="flex items-center gap-[4px] mr-[6px] cursor-default"
            >
              <FontAwesomeIcon
                icon={faCloudArrowUp}
                className="text-[12px] text-gray-700"
              />
              <p className="text-xs text-gray-600">
                {showPostUpdating ? "Saving..." : "Saved"}
              </p>
            </div>
            <Tooltip id="copy-post-link">Share Link</Tooltip>
            <SButton
              data-tooltip-id="copy-post-link"
              icon={faCopy}
              onClick={() => {
                // http://localhost:3000/?redirectType=post&teamId=60&organizationId=1684&postId=10589
                navigator.clipboard.writeText(
                  `${window.location.origin}/?redirectType=post&teamId=${postData?.workspaceId}&organizationId=${postData?.organizationId}&postId=${postData?.id}`
                );
                toast("Copied to clipboard", "success");
              }}
              size="md"
              type="secondaryGray"
            />
            {windowWidth >= 1000 && (
              <SButton
                icon={faX}
                size="md"
                type="secondaryGray"
                onClick={async () => {
                  await handleClose();
                }}
              />
            )}
          </div>
        </div>
        <div className="flex h-full w-full rounded-t-[24px] max-md:flex-col max-md:rounded-b-0">
          {personalInfo?.userRole !== OrganizationUserRole.VIEWER &&
            (windowWidth >= 900 ? (
              <div className="w-[60%] h-[100%] bg-white border border-gray-200 rounded-tl-[24px] flex flex-col mb-[20px] max-md:w-full max-md:rounded-tl-[0px] max-md:border-0 max-md:m-0 max-md:rounded-b-0">
                <CreationHeader onClose={handleClose} />
                <div
                  id="creation-side"
                  className={`${activeTab !== "elements" ? (tabsOverlay ? "py-[20px]" : "py-[20px]") : "py-0"} ${activeTab !== "elements" && "px-[36px] max-md:px-[16px]"}  h-full overflow-hidden relative max-md:p-0`}
                >
                  {tabsOverlay && (
                    <div
                      className={`absolute w-full h-[calc(100%+20px)] top-[-20px] left-0 bg-[rgba(152,152,152,0.8)] z-[100]`}
                    />
                  )}
                  {activeTab === "brief" &&
                    (sessionErrored ? (
                      <SessionErrored />
                    ) : (
                      <Brief setIsUpdatedByCopilot={setIsUpdatedByCopilot} />
                    ))}
                  {activeTab === "accounts" && <Channels />}
                  {activeTab === "media" && <Media />}
                  {activeTab === "elements" && <Frames />}
                </div>
              </div>
            ) : (
              <MobileSectionsDrawer
                setIsUpdatedByCopilot={setIsUpdatedByCopilot}
                onCreationClose={handleClose}
              />
            ))}
          <PreviewSide
            isUpdatedByCopilot={isUpdatedByCopilot}
            setIsUpdatedByCopilot={setIsUpdatedByCopilot}
          />
        </div>
      </Drawer>
    </>
  );
};
export default Creation;
