import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faReddit,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import React, { Fragment, useEffect, useState } from "react";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import { useCreationStore } from "../../../store/creationStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useBrandStore } from "../../../store/brandStore";
import { faAdd, faPlus, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../design-system/SButton";
import WhichSocialNetworksSelector from "../../utils/WhichSocialNetworksSelector";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";

interface ISocMedia {
  icon: IconProp;
  color: string;
  type: SocialNetworkType;
}

function SocMediaSwitcher({ isHovered }: { isHovered: boolean }) {
  const { postData, activeSoc, setActiveSoc, isSocialScoreLoading } =
    useCreationStore();
    const [isLoading, setIsLoading] = useState(true);
  const [socMedias, setSocMedias] = useState<ISocMedia[]>([]);
  const { toggleSocialNetworkInPost } = useCreationHelperFunctions();

  useEffect(() => {
    if (postData) {
      setIsLoading(true);
      const updatedSocMedias: ISocMedia[] = postData.socialNetworkPosts.map(
        (post) => {
          let icon: IconProp = faFacebook;
          let color = "#000000";
          switch (post.socialNetworkType) {
            case SocialNetworkType.TWITTER:
              icon = faXTwitter;
              color = "#000000";
              break;
            case SocialNetworkType.LINKEDIN:
              icon = faLinkedin;
              color = "#0077B5";
              break;
            case SocialNetworkType.INSTAGRAM:
              icon = faInstagram;
              color = "#E1306C";
              break;
            case SocialNetworkType.PINTEREST:
              icon = faPinterest;
              color = "#E60023";
              break;
            case SocialNetworkType.FACEBOOK:
              icon = faFacebook;
              color = "#0866FF";
              break;
          }
          return {
            icon,
            color,
            type: post.socialNetworkType,
          };
        }
      );
      setSocMedias(updatedSocMedias);
      setIsLoading(false);
    }
  }, [postData]);

  const [
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
  ] = useState(false);

  if (isLoading) {
    return (
      <div className="flex relative mb-[38px] group border-[1px] border-gray-300 w-fit rounded-[8px] h-[40px] bg-base-white max-md:mb-0 items-center justify-center px-4">
        <FontAwesomeIcon icon={faSpinner} spin className="text-gray-500" />
      </div>
    );
  }

  return (
    <>
      {isWhichSocialNetworksSelectorOpen && (
        <WhichSocialNetworksSelector
          saveAllOnClose={false}
          isOpen={isWhichSocialNetworksSelectorOpen}
          onClose={() => setIsWhichSocialNetworksSelectorOpen(false)}
          additionalFunction={(socialNetworkTypes, append) => {
            toggleSocialNetworkInPost({
              socialNetworkTypes,
              append,
            });
          }}
        />
      )}
      <div className="flex relative mb-[38px] group border-[1px] border-gray-300 w-fit rounded-[8px] h-[40px] bg-base-white max-md:mb-0">
        <div className="flex rounded-[8px] overflow-hidden">
        {socMedias.map((item, i) => (
          <Fragment key={item.type}>
            {isSocialScoreLoading && (
              <Tooltip id="social-score-tooltip" place="top">
                Please wait for the social score to generate.
              </Tooltip>
            )}
            <div
              data-tooltip-id="social-score-tooltip"
              onClick={() => {
                if (activeSoc !== item.type && !isSocialScoreLoading) {
                  setActiveSoc(item.type);
                }
              }}
              className={`${i !== 0 && "border-l-[1px]"} ${activeSoc === item.type && "bg-gray-100"} cursor-pointer flex border-l-gray-300 justify-center  h-auto w-[44px] items-center`}
            >
              <FontAwesomeIcon
                icon={item.icon}
                color={item.color}
                fontSize={"18px"}
                className={"max-md:14px"}
              />
            </div>
          </Fragment>
        ))}
        </div>
        <SButton
          onClick={() => {
            setIsWhichSocialNetworksSelectorOpen(true);
          }}
          className={`flex absolute left-[calc(100%+12px)] top-[50%] translate-y-[-50%] max-h-[32px] max-w-[32px] cursor-pointer border-l-gray-300 justify-center items-center`}
          size="sm"
          type="secondaryGray"
          icon={faAdd}
          iconProps={{
            fontSize: "14px",
          }}
        />
      </div>
    </>
  );
}

export default SocMediaSwitcher;
