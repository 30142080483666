import React, { useEffect, useState } from "react";
import ImageSelectorItem from "./ImageSelectorItem";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import { ISocialNetworkPostFileVM } from "../../../../utils/interfaces/IPost";
import { isVideo } from "../../../Library/hooks/getFileType";

function ImageSelectorSidebar() {
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();

  return (
    <div className="max-w-[224px] min-w-[224px] pt-[20px] w-full bg-graycool-50 border-r border-r-gray-200">
      <p className="text-sm-sb text-gray-800 px-[36px]">Selected Images</p>
      <div
        className="flex flex-col gap-[24px] mt-[16px] pb-[20px] overflow-auto items-center"
        style={{ height: "calc(100% - 32px)" }}
      >
        {getActiveSocialNetworkPost() &&
          getActiveSocialNetworkPost()!
            .files.filter((item) => !isVideo(item?.url || ""))
            .map((item) => <ImageSelectorItem image={item} />)}
      </div>
    </div>
  );
}

export default ImageSelectorSidebar;
