import { faChevronDown, faUpload } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";
import { useRef, useState } from "react";
//@ts-ignore
import DropboxIcon from "../../assets/icons/dropbox.svg";
//@ts-ignore
import MonitorSVG from "../../assets/icons/monitor.svg";
//@ts-ignore
import FolderSVG from "../../assets/icons/folder.svg";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";
import { useAccountStore } from "../../store/accountStore";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { ViewerRestrictTooltip } from "../utils/ViewerRestrictTooltip";
import { useGetWorkspaceUsage } from "../../api/workspaces/limits/get";
import { RestrictExpiredTooltip } from "../utils/RestrictExpiredTooltip";

export interface ILibraryDropdownProps {
  handleFolder: () => void;
  handleFiles: () => void;
  handleDropbox: () => void;
  className?: string;
  btnClassName?: string;
  isContent?: boolean;
}

export const LibraryDropdown = ({
  handleFolder,
  handleFiles,
  handleDropbox,
  className,
  btnClassName,
  isContent = false,
}: ILibraryDropdownProps) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useHandleOutsideClick({ ref: dropdownRef, callBack: () => setOpen(false) });
  const { personalInfo } = useAccountStore();
  const { data: workspaceUsage } = useGetWorkspaceUsage();

  return (
    <SButton
      className={`${btnClassName}`}
      onClick={() => setOpen((prevValue) => !prevValue)}
      size={isContent ? "xl" : "md"}
      type={isContent ? "secondaryGray" : "secondaryColor"}
      lIcon={faUpload}
      disabled={
        personalInfo?.userRole === OrganizationUserRole.VIEWER ||
        !workspaceUsage?.data.canUserAddAssets
      }
      children={
        <div
          id="restrict-upload-tooltip"
          ref={dropdownRef}
          className="relative flex items-center gap-2 z-10"
        >
          <ViewerRestrictTooltip title="Viewers are not allowed to upload files." />
          <RestrictExpiredTooltip
            available={workspaceUsage?.data.canUserAddAssets ?? false}
          />
          <p>Upload</p>
          {open && (
            <div
              className={`${className} min-w-[240px] border border-gray-200 text-gray-700 absolute ${isContent ? "-translate-x-1/3 right-auto md:translate-x-1/4 md:right-1/2" : "translate-x-1/4 right-1/2"}  top-[40px] bg-white flex flex-col gap-[9px] rounded-[8px] shadow-lg`}
            >
              <div
                className="w-full hover:bg-gray-100 transition-all flex items-center gap-[14px] pl-4 py-[9px]"
                onClick={handleFolder}
              >
                <img className="w-5" src={FolderSVG} alt="Create Folder" />
                <h1 className="text-sm-m">Create Folder</h1>
              </div>
              <div className="flex w-full items-center">
                <hr className="border-gray-200 w-1/3" />
                <h1 className="text-xs-m w-full">Upload From</h1>
                <hr className="border-gray-200 w-full" />
              </div>
              <div
                className="w-full flex hover:bg-gray-100 transition-all items-center gap-[14px] pl-4 py-[9px]"
                onClick={handleFiles}
              >
                <img
                  className="w-5"
                  src={MonitorSVG}
                  alt="Upload From Device"
                />
                <h1 className="text-sm-m">Device</h1>
              </div>
              <div
                className="w-full flex hover:bg-gray-100 transition-all items-center gap-[14px] pl-4 py-[9px]"
                onClick={handleDropbox}
              >
                <img
                  className="w-5"
                  src={DropboxIcon}
                  alt="Upload From Dropbox"
                />
                <h1 className="text-sm-m">Dropbox</h1>
              </div>
            </div>
          )}
        </div>
      }
      rIcon={faChevronDown}
    />
  );
};
