import React, { useEffect, useRef, useState } from "react";
import { useCreationStore } from "../../../../../store/creationStore";
import { CSSTransition } from "react-transition-group";
import DefaultState from "./DefaultState";
import StocksView from "./StocksView";
import GeneratedSelector from "./GeneratedSelector";
import SelectorTags from "./SelectorTags";
import Input from "./Input";
import { useHandleOutsideClick } from "../../../../../hooks/useHandleOutsideClick";
import { ImageGenerationStyle } from "../../../../../utils/enums/Enums";
import { useGenerateStocks } from "./hooks/useGenerateStocks";
import { useGetWindowHeight } from "../../../../../hooks/useGetWindowHeight";
import { GifsView } from "./GifsView";
import { ShortcutButtons } from "../ShortcutButtons";
import { KlipyType } from "../../../../../utils/interfaces/IKlipyGifs";

export interface IHelperInputProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  dropboxRef: React.RefObject<HTMLInputElement>;
  selectFile: (options: { multiple: boolean }) => void;
}

function HelperInput({
  setOpenModal,
  dropboxRef,
  selectFile,
}: IHelperInputProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { setTabsOverlay } = useCreationStore();
  const containerRef = useRef<any>(null);

  useEffect(() => {
    setTabsOverlay(isOpen);
  }, [isOpen]);
  const [activeTab, setActiveTab] = useState<
    "generator" | "stocks" | "search" | "selector" | "gifs"
  >("generator");
  const [activeStyleTag, setActiveStyleTag] = useState<ImageGenerationStyle>(1);

  useHandleOutsideClick({
    ref: containerRef,
    callBack: () => setIsOpen(false),
    excludedIds: ["expanded-image-modal"],
  });
  const [inputValue, setInputValue] = useState("");
  const [gifSearch, setGifSearch] = useState("");
  const [type, setType] = useState<"Gifs" | "Clips">("Gifs");

  useGenerateStocks({ isOpen });
  const { windowHeight } = useGetWindowHeight();
  return (
    <>
      <div
        ref={containerRef}
        className={`
            absolute top-0 left-0 z-[101]
            transition-all duration-500 ease-in-out w-full ${windowHeight > 860 ? "h-fit" : isOpen ? "max-sm-m:h-full h-fit" : "h-fit"}
            ${isOpen ? "max-w-[100%] 2xl:max-w-[800px]" : "max-w-[80%]"} 
            ${isOpen ? "py-[16px] mt-[14px] rounded-[8px] bg-base-white shadow-xl3" : "py-[0] mt-[0] rounded-[8px] bg-transparent shadow-none"}
          `}
      >
        <Input
          activeStyleTag={activeStyleTag}
          inputValue={inputValue}
          setInputValue={setInputValue}
          activeTab={activeTab}
          isOpen={isOpen}
          setActiveTab={setActiveTab}
          setIsOpen={setIsOpen}
          setGifSearch={setGifSearch}
          setType={setType}
          type={type}
        />
        {/* {activeTab !== "search" && ( */}
        <CSSTransition
          in={isOpen}
          timeout={500}
          classNames="expand"
          unmountOnExit
        >
          <div className="expand-wrapper">
            {activeTab === "selector" && (
              <SelectorTags
                activeStyleTag={activeStyleTag}
                setActiveStyleTag={setActiveStyleTag}
              />
            )}
            {activeTab === "gifs" && (
              <GifsView
                type={type === "Gifs" ? KlipyType.GIF : KlipyType.CLIP}
                gifSearch={gifSearch}
              />
            )}
            {activeTab === "generator" && (
              <DefaultState
                setInputValue={setInputValue}
                setActiveTab={setActiveTab}
                activeStyleTag={activeStyleTag}
                setActiveStyleTag={setActiveStyleTag}
              />
            )}
            {activeTab === "stocks" && (
              <StocksView
                activeTab={activeTab}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            )}
            {activeTab === "search" && <></>}
            {activeTab === "selector" && (
              <GeneratedSelector setActiveTab={setActiveTab} />
            )}
          </div>
        </CSSTransition>
      </div>
      {!isOpen && (
        <ShortcutButtons
          setOpenModal={setOpenModal}
          dropboxRef={dropboxRef}
          selectFile={selectFile}
          setIsOpen={setIsOpen}
          setActiveTab={setActiveTab}
        />
      )}
    </>
  );
}

export default HelperInput;
