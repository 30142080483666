import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useRef } from "react";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";

export interface ISSmallMenuPage {
  icon?: IconProp;
  additionalItem?: ReactNode;
  text: string | React.ReactNode;
  color?: string;
  handleClick: (e: React.MouseEvent<any, globalThis.MouseEvent>) => void;
}

export interface ISSmallMenu {
  data: ISSmallMenuPage[][];
  isOpen: boolean;
  setIsOpen: (p: boolean) => void;
  excludedId?: string;
  className?: string;
  topContent?: React.ReactNode;
}

function SSmallMenu({
  topContent,
  excludedId,
  isOpen,
  className,
  setIsOpen,
  data,
}: ISSmallMenu) {
  const wrapperRef = useRef<any>();
  useHandleOutsideClick({
    callBack: () => setIsOpen(false),
    ref: wrapperRef,
    excludedIds: [excludedId || ""],
  });
  return (
    <div
      ref={wrapperRef}
      className={`${isOpen ? "visible" : "hidden"} ${className} w-fit absolute top-[calc(100%+6px)] left-0 bg-base-white border border-gray-200 shadow-lg rounded-[8px] overflow-hidden`}
    >
      {topContent && topContent}
      {data.map((pItem) => (
        <div className={`border-b border-gray-200 pb-[4px]`}>
          {pItem.map((cItem) => (
            <div
              onClick={(e) => {
                setIsOpen(false);
                cItem.handleClick(e);
              }}
              className="flex items-center gap-[10px] py-[10px] px-[16px] hover:bg-gray-50 cursor-pointer"
            >
              {cItem.icon && (
                <FontAwesomeIcon
                  className={`text-[12px] ${cItem.color || "text-gray-500"}`}
                  icon={cItem.icon}
                />
              )}
              {cItem.additionalItem && cItem.additionalItem}
              <p
                className={`text-sm-m capitalize ${cItem.color || "text-gray-700"}`}
              >
                {cItem.text}
              </p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default SSmallMenu;
