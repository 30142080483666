import {
  faChevronDown,
  faChevronUp,
  faSquare1,
  faSquare2,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";
import { useContentIdeas } from "./hooks/useContentIdeas";
import { ContentIdeaSkeleton } from "./ContentIdeaSkeleton";
import { Dispatch, SetStateAction } from "react";
//@ts-ignore
import memo from "../../assets/emojies/memo.svg";

export const ContentIdea = ({
  setIsWhichSocialNetworksSelectorOpen,
}: {
  setIsWhichSocialNetworksSelectorOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    postIdeas,
    setViewAll,
    getVisiblePostIdeaCount,
    handleOpenPost,
    viewAll,
    windowWidth,
    isFetching,
  } = useContentIdeas();

  return (
    <div className="w-full mt-4 border border-gray-200 bg-gray-50 rounded-xl p-3">
      <h2 className="text-md-sb mb-3 text-gray-700">Today's Post Ideas</h2>
      {!isFetching && (
        <div className="w-full grid gap-3 grid-cols-5 max-lg:grid-cols-5 max-md:grid-cols-4 max-sm-m:grid-cols-2 max-sm:grid-cols-2">
          {postIdeas?.data
            .slice(0, getVisiblePostIdeaCount())
            .map((idea, i) => {
              return (
                <>
                  <SButton
                    onClick={() => {
                      handleOpenPost({
                        postIdea: idea.idea,
                        onSocialNetworkSelectorOpen: () =>
                          setIsWhichSocialNetworksSelectorOpen(true),
                      });
                    }}
                    className={`h-auto flex flex-col !gap-1 !items-start justify-start`}
                    type="secondaryGray"
                    key={idea.id}
                  >
                    <div className="w-full flex justify-between">
                      <p className="text-gray-500">#{i + 1} Idea</p>
                      <img src={memo} className="w-[14px]" />
                    </div>
                    <p className="text-sm text-start mb-auto">{idea.idea}</p>
                  </SButton>
                </>
              );
            })}
        </div>
      )}
      {isFetching && (
        <div className="flex flex-wrap gap-3 items-center">
          <ContentIdeaSkeleton />
          <ContentIdeaSkeleton />
          {windowWidth > 1000 && <ContentIdeaSkeleton />}
        </div>
      )}
      {windowWidth <= 1200 && (
        <div className="w-ful flex justify-center items-center">
          <SButton
            lIcon={!viewAll ? faChevronDown : faChevronUp}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "auto" });
              setViewAll((val) => !val);
            }}
            type="tertiaryGray"
            className="mt-3 text-center flex items-center gap-[6px]"
          >
            <p>{!viewAll ? "View All" : "Minimize"}</p>
          </SButton>
        </div>
      )}
    </div>
  );
};
