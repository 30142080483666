import React from "react";
import SModal from "../../../../design-system/SModal";
import { useFramesStore } from "../../../../store/framesStore";
import { ISocialNetworkPostFileVM } from "../../../../utils/interfaces/IPost";
import { useTemplateRendererHelperFunctions } from "../../hooks/useTemplateRendererHelperFunctions";

function AgreementOfRemoveProgress({
  isOpen,
  image,
  setIsOpen,
}: {
  image: ISocialNetworkPostFileVM;
  isOpen: boolean;
  setIsOpen: (p: null | ISocialNetworkPostFileVM) => void;
}) {
  const { setActiveRenderedFrame, setActiveFrame, setSelectedImage } =
    useFramesStore();
  const { saveTemplate } = useTemplateRendererHelperFunctions();
  return (
    <SModal
      title={"Save template first"}
      subtitle={`otherwise you will lose the current template`}
      type="warning"
      isOpen={isOpen}
      setIsOpen={(p: null | ISocialNetworkPostFileVM) => setIsOpen(p)}
      handleSubmit={() => alert("asd")}
      handleCancel={() => {
        setActiveRenderedFrame(undefined);
        setActiveFrame(undefined);
        setSelectedImage(image);
        setIsOpen(null);
      }}
      actionButtonText={"Save template"}
      cancelButtonText={"Discard changes"}
      actionButtonProps={{
        onClick: () => {
          saveTemplate();
          setActiveRenderedFrame(undefined);
          setActiveFrame(undefined);
          setSelectedImage(image);
          setIsOpen(null);
        },
        className: "w-[400px]",
      }}
    />
  );
}

export default AgreementOfRemoveProgress;
