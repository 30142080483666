import React from "react";
import SButton from "../../../../design-system/SButton";
import { usePostCopilotSession } from "../../../../api/copilot/session/post";
import { CopilotType } from "../../../../utils/enums/Enums";
import { useCreationStore } from "../../../../store/creationStore";

function SessionErrored() {
  const { postData, setCopilotSessionId, setSessionErrored } =
    useCreationStore();
  const { mutateAsync: createCopilotSession } = usePostCopilotSession();
  return (
    <div className="bg-base-white border-[1px] flex justify-center items-center rounded-[12px] h-full border-gray-200 shadow-xs flex-col max-md:rounded-[0px] max-md:border-0 max-md:rounded-b-0">
      <p className="display-xs-sb text-gray-600 text-center">
        👀 Oops! The chat failed to load{" "}
      </p>
      <p className="text-gray-600 text-sm mt-[16px] text-center max-w-[500px]">
        Your session is experiencing an issue. Please close the creation and
        reopen it. If the chat doesn't load, please{" "}
        <span
          className="text-sm-sb text-brand-600 cursor-pointer"
          onClick={() => {
            //@ts-ignore
            window.HubSpotConversations.widget.open();
          }}
        >
          get in touch with our support.
        </span>
      </p>
      <div className="flex items-center gap-[12px] w-[60%]">
        <div className="w-full h-[1px] bg-gray-200" />
        <p className="text-xs text-gray-300 my-[16px]">or</p>
        <div className="w-full h-[1px] bg-gray-200" />
      </div>
      <SButton
        onClick={() => {
          if (postData)
            createCopilotSession({
              type: CopilotType.POST,
              postId: postData.id,
            }).then((res) => {
              setCopilotSessionId(res.data.id);
              setSessionErrored(false);
            });
        }}
        size="md"
        type="secondaryColor"
      >
        Start new session
      </SButton>
    </div>
  );
}

export default SessionErrored;
