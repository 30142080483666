import React, { useMemo } from "react";
import SButton from "../../design-system/SButton";
import { faAdd, faCircle, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { IBaseData } from "../../utils/interfaces/IBase";
import { ITeamVM } from "../../utils/interfaces/ITeam";
import LettersAvatar from "../utils/LettersAvatar";
import { usePutSwitchTeam } from "../../api/workspaces/swich/put";
import { useGetWorkspaces } from "../../api/workspaces/get";
import { IWorkspaceVM } from "../../utils/interfaces/IWorkspace";
import SRadio from "../../design-system/SRadio";

function WorkspaceSwitcher({
  teams,
  currentTeam,
  setIsMobileMenuOpen,
  setWorkspaceSelectorOpen,
}: {
  teams?: IBaseData<IWorkspaceVM[]>;
  currentTeam?: IBaseData<ITeamVM>;
  setIsMobileMenuOpen?: (open: boolean) => void;
  setWorkspaceSelectorOpen?: (open: boolean) => void;
}) {
  const { mutateAsync: switchTeam, isPending } = usePutSwitchTeam();

  const sortedTeams = useMemo(() => {
    if (!teams || !teams.data) return [];

    const currentTeamItem = teams.data.find(
      (item) => item.id === currentTeam?.data.id
    );
    const otherTeams = teams.data.filter(
      (item) => item.id !== currentTeam?.data.id
    );

    return currentTeamItem ? [currentTeamItem, ...otherTeams] : otherTeams;
  }, [teams, currentTeam?.data.id]);

  return (
    <div
      className={`
        ${teams ? (sortedTeams?.length > 1 ? "pt-[0px]" : "pt-[12px]") : "pt-[24px]"}
          bg-base-white flex flex-col w-[380%] max-md:w-full items-center absolute bottom-[100%] rounded-r-[12px] max-md:rounded-t-[12px] left-0 z-[1] border-t border-t-gray-200 shadow-xs
        `}
    >
      {/* <SButton
        size="sm"
        type="secondaryGray"
        rIcon={faAdd}
        className="mb-[12px]"
      >
        Create workspace
      </SButton> */}
      <div className="px-[20px] flex flex-col w-full max-h-[300px] overflow-auto">
        {sortedTeams &&
          sortedTeams.map((item, index) => (
            <div
              onClick={() => {
                switchTeam({ teamId: item.id });
                setIsMobileMenuOpen && setIsMobileMenuOpen(false);
                setWorkspaceSelectorOpen && setWorkspaceSelectorOpen(false);
              }}
              className={`${index !== sortedTeams.length - 1 ? "border-b" : "border-b-0"} border-b-gray-200 py-[20px] flex items-center justify-between gap-[6px] cursor-pointer`}
            >
              <div className="flex gap-[12px] items-center">
                <LettersAvatar text={item.name} />
                <p className="text-sm-sb text-gray-700 max-w-[100px] w-full">
                  {item.name}
                </p>
              </div>
              <SRadio
                size="sm"
                disabled={isPending}
                checked={item.id === currentTeam?.data?.id}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default WorkspaceSwitcher;
