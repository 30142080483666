import { useState } from "react";
import PageHeading from "../../components/PageHeading";
import { ITabItem } from "../../design-system/STertiaryTabs";
import BrandBook from "./BrandBook";
import BrandMembers from "./BrandMembers";
import BrandConnections from "./BrandConnections";
import BrandSettings from "./BrandSettings";
import RegenerateBrandModal from "../../components/brand/modals/RegenerateBrandModal";
import { useBrandEffects } from "../../components/brand/hooks/useBrandEffects";
import { BrandInviteMemberModal } from "../../components/brand/BrandInviteMemberModal";
import SButton from "../../design-system/SButton";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { useGetUser } from "../../hooks/useUserInfo";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useSetDefaultBrandSection } from "../../components/brand/hooks/useSetDefaultBrandSection";
import { useNavigate } from "react-router-dom";

const getBySelected = (selected: number, arr: string[]) => {
  switch (selected) {
    case 0:
      return arr[0];
    case 1:
      return arr[1];
    case 2:
      return arr[2];
    case 3:
      return arr[3];
    default:
      return arr[0];
  }
};

function Brand() {
  const [selected, setSelected] = useState(-1);
  const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);
  const { data: userInfo } = useGetUser();
  const tabItems: ITabItem[] = [
    {
      index: 0,
      text: "Brandbook",
    },
    {
      index: 1,
      text: "Members",
    },
    {
      index: 2,
      text: "Accounts",
    },
    ...(userInfo?.data.data.userRole !== OrganizationUserRole.VIEWER
      ? [
          {
            index: 3,
            text: "Settings",
          },
        ]
      : []),
  ];

  useBrandEffects({
    setIsRegenerateModalOpen,
    isRegenerateModalOpen,
    setSelected,
  });
  const [inviteModal, setInviteModal] = useState(false);
  useSetDefaultBrandSection({ setSelected });
  const navigate = useNavigate();
  return (
    <>
      {inviteModal &&
        userInfo?.data.data.userRole !== OrganizationUserRole.VIEWER && (
          <BrandInviteMemberModal
            inviteModal={inviteModal}
            setInviteModal={setInviteModal}
          />
        )}
      {isRegenerateModalOpen &&
        userInfo?.data.data.userRole !== OrganizationUserRole.VIEWER && (
          <RegenerateBrandModal
            isOpen={isRegenerateModalOpen}
            onClose={() => setIsRegenerateModalOpen(false)}
          />
        )}
      <div className="w-full pt-[24px] pb-[100px]">
        <PageHeading
          title={getBySelected(selected, [
            "Brand Book",
            "Connected Accounts",
            "Members",
            "Settings",
          ])}
          subtitle={getBySelected(selected, [
            "Manage, edit or regenerate your brand elements and details.",
            "Manage your brand's social network accounts.",
            "Manage your brand members and their account permissions.",
            "Brand Settings.",
          ])}
          tabsProps={{
            items: tabItems,
            selected: selected,
            setSelected: (i) => {
              setSelected(i);
              if (tabItems[i as number].text === "Connected accounts") {
                navigate("/brand?section=accounts");
              } else {
                navigate(
                  "/brand?subsection=" +
                    tabItems[i as number].text?.toLocaleLowerCase()
                );
              }
            },
            rItems: (
              <>
                {selected === 0 &&
                  userInfo?.data.data.userRole !==
                    OrganizationUserRole.VIEWER && (
                    <SButton
                      onClick={() => setIsRegenerateModalOpen(true)}
                      children="Regenerate Brand"
                      type={"secondaryColor"}
                      size={"md"}
                    />
                  )}
                {selected === 1 &&
                  userInfo?.data.data.userRole !==
                    OrganizationUserRole.VIEWER && (
                    <SButton
                      onClick={() => setInviteModal(true)}
                      children="Add Brand Member"
                      rIcon={faUserPlus}
                      type={"secondaryColor"}
                      size={"md"}
                    />
                  )}
              </>
            ),
          }}
        />
        {selected === 0 && <BrandBook />}
        {selected === 1 && <BrandMembers setInviteModal={setInviteModal} />}
        {selected === 2 && <BrandConnections />}
        {selected === 3 &&
          userInfo?.data.data.userRole !== OrganizationUserRole.VIEWER && (
            <BrandSettings />
          )}
      </div>
    </>
  );
}

export default Brand;
