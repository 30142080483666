import {
  faArrowsUpDown,
  faChevronDown,
  faChevronRight,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import SidebarMenu from "./SidebarMenu";
import { usePutSwitchBrand } from "../../api/brand/switch/put";
import { useBrandStore } from "../../store/brandStore";
import { useGetCurrentBrand } from "../../api/brand/current/get";
import SButton from "../../design-system/SButton";
import { useNavigate } from "react-router-dom";
import { toast } from "../../hooks/toast";
import { useGetWorkspaceUsage } from "../../api/workspaces/limits/get";
import BrandSelector from "./BrandSelector";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";
import { OrganizationType } from "../../utils/enums/Enums";

function BrandSwitcher({
  setIsBrandSelectorOpen,
  isBrandSelectorOpen,
  setIsMobileMenuOpen,
}: {
  setIsBrandSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isBrandSelectorOpen: boolean;
  setIsMobileMenuOpen?: (open: boolean) => void;
}) {
  const navigate = useNavigate();
  const { data: workspaceUsage } = useGetWorkspaceUsage();
  const { data: currentBrand, isError } = useGetCurrentBrand();
  const wrapperRef = useRef<any>();
  useHandleOutsideClick({
    callBack: () => setIsBrandSelectorOpen(false),
    ref: wrapperRef,
    excludedIds: ["sidebar-brand-switcher"],
  });
  return (
    <div
      ref={wrapperRef}
      id="sidebar-brand-switcher"
      className={`
      ${isBrandSelectorOpen ? "bg-base-white border-transparent pt-[6px] rounded-t-[12px]" : "rounded-[8px] bg-gray-50 py-[10px] px-[4px]"}
      border border-gray-200 ${isBrandSelectorOpen ? "m-[6px]" : "m-[12px]"} "relative mb-[0] transition-all"`}
    >
      {!isError && currentBrand ? (
        <div className="relative px-[6px]">
          <div
            onClick={() => setIsBrandSelectorOpen((prev) => !prev)}
            className={`
              ${isBrandSelectorOpen ? "bg-gray-50 px-[10px] py-[10px] border border-gray-200 rounded-[8px]" : "border-transparent bg-transparent"}
              flex justify-between items-center w-full cursor-pointer border gap-[4px] md:justify-center`}
          >
            <div className="flex max-md:gap-[10px] relative items-center md:justify-center">
              <img
                className="md:max-w-full md:min-w-full max-w-[32px] min-w-[32px] min-h-[32px] max-h-[32px] w-full object-contain rounded-[6px]"
                src={
                  currentBrand.data.secondLogo
                    ? currentBrand.data.secondLogo
                    : currentBrand.data.logo
                }
                alt="logo"
              />
              <div className="md:hidden">
                <p className="text-xs text-gray-400">
                  {currentBrand.data.organizationType ===
                    OrganizationType.COMPANY && "Company"}
                  {currentBrand.data.organizationType ===
                    OrganizationType.PERSONAL && "Personal"}
                </p>
                <p
                  className="w-[80px] max-sm:w-full max-sm:text-wrap truncate text-sm-sb text-gray-700"
                  title={currentBrand.data.name}
                >
                  {currentBrand.data.name}
                </p>
              </div>
            </div>
          </div>
          {isBrandSelectorOpen && (
            <BrandSelector
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              setIsBrandSelectorOpen={setIsBrandSelectorOpen}
              currentBrand={currentBrand}
            />
          )}
        </div>
      ) : (
        <SButton
          type="secondaryColor"
          className="w-full"
          lIcon={faPlus}
          onClick={() => {
            workspaceUsage && workspaceUsage.data?.canUserAddBrands
              ? navigate("/create-brand")
              : toast(
                  "You have reached the limit of brands you can create, upgrade plan or contact us",
                  "error"
                );
            setIsMobileMenuOpen && setIsMobileMenuOpen(false);
          }}
        >
          <span className="md:hidden">Create Brand</span>
        </SButton>
      )}
    </div>
  );
}

export default BrandSwitcher;
