import { act } from "react";
import { downloadFile } from "../../../../../hooks/functions/downloadFile";
import { getLastSegment } from "../../../../../hooks/functions/queries";
import { useCreationStore } from "../../../../../store/creationStore";
import {
  ISingleFIleVM,
  IUploadFIleVM,
} from "../../../../../utils/interfaces/IAssets";
import {
  ISocialNetworkPostFileVM,
  IStockImageVM,
} from "../../../../../utils/interfaces/IPost";
import { ISocialNetworkVM } from "../../../../../utils/interfaces/ISocialNetworks";
import { useCreationHelperFunctions } from "../../../hooks/useCreationHelperFunctions";
import { useUpdatePost } from "../../../hooks/useUpdatePost";
import {
  convertLibraryFileIntoSocialNetworkPostFile,
  stockInterfaceToPostFile,
} from "./creationLibraryConverters";
import _ from "lodash";
import { usePostFile } from "../../../../../api/library/files/post";
import { usePostGenerateImage } from "../../../../../api/creation/generateImage/post";
import { ImageGenerationStyle } from "../../../../../utils/enums/Enums";
import { IBaseData } from "../../../../../utils/interfaces/IBase";
import { useQueryClient } from "react-query";
import { isImage, isVideo } from "../../../../Library/hooks/getFileType";
import { toast } from "../../../../../hooks/toast";

export const useCreationLibraryHelperFunctions = () => {
  const { updateFilesUI, updateFullPost } = useUpdatePost();
  const { postData, setPostData, setVideoSelectionAgreementModalOpen } =
    useCreationStore();
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const { mutateAsync: uploadFile } = usePostFile();
  const { mutateAsync: generateImageRequest } = usePostGenerateImage();

  const handleMediaSelection = (
    files: ISocialNetworkPostFileVM[],
    newFile: ISingleFIleVM,
    isVideoFile: boolean,
    agreed: boolean,
    forAll: boolean
  ): boolean => {
    if (
      isVideoFile &&
      files.some((file) => isImage((file.url || file.templateOutputUrl)!)) &&
      !agreed
    ) {
      setVideoSelectionAgreementModalOpen({ file: newFile, forAll });
      return true;
    }
    if (
      !isVideoFile &&
      files.some((file) => isVideo((file.url || file.templateOutputUrl)!)) &&
      !agreed
    ) {
      setVideoSelectionAgreementModalOpen({ file: newFile, forAll });
      return true;
    }
    return false;
  };

  const updateFiles = (
    files: ISocialNetworkPostFileVM[],
    newFile: ISingleFIleVM,
    removeIfExist = true
  ): ISocialNetworkPostFileVM[] => {
    if (isVideo(newFile.url)) {
      if (files.some((item) => item.url === newFile.url)) {
        files = [];
      } else {
        files = [convertLibraryFileIntoSocialNetworkPostFile(newFile)];
      }
    } else {
      files = files.filter(
        (file) => !isVideo((file.url || file.templateOutputUrl)!)
      );
      if (files.some((item) => item.url === newFile.url) && removeIfExist) {
        files = files.filter((item) => item.url !== newFile.url);
      } else {
        files.push(convertLibraryFileIntoSocialNetworkPostFile(newFile));
      }
    }
    return files;
  };

  const selectUploadedMedia = async (res: IBaseData<IUploadFIleVM>) => {
    if (!postData) return;
    let tmpPostData = _.cloneDeep(postData);
    tmpPostData.socialNetworkPosts.forEach((item) => {
      res.data.uploadedFiles.forEach((file) => {
        item.files = updateFiles(item.files, file);
      });
    });
    setPostData(tmpPostData);
    await updateFullPost(tmpPostData);
  };

  const selectMedia = (file: ISingleFIleVM, agreed = false) => {
    let activeFiles = _.cloneDeep(getActiveSocialNetworkPost()?.files) || [];
    if (
      handleMediaSelection(activeFiles, file, isVideo(file.url), agreed, false)
    )
      return;
    activeFiles = updateFiles(activeFiles, file);
    updateFilesUI({ sendRequest: true, files: activeFiles });
  };

  const insertMediaForAllSocialNetworkType = async (
    file: ISingleFIleVM,
    agreed = false,
    removeIfExist = true,
    sendRequest = true
  ) => {
    let tmpPostData = _.cloneDeep(useCreationStore.getState().postData);
    if (!tmpPostData) return;
    if (handleMediaSelection([], file, isVideo(file.url), agreed, true)) return;

    tmpPostData.socialNetworkPosts.forEach((item) => {
      item.files = updateFiles(item.files, file, removeIfExist);
    });
    // setPostData(tmpPostData);
    if (sendRequest)
      await updateFullPost(tmpPostData).then((res) => {
        setPostData(res.data);
      });
  };

  const downloadMediasFromActiveSocialNetworkType = async () => {
    const activeFiles = getActiveSocialNetworkPost()?.files || [];
    if (activeFiles.length === 0) {
      toast("No images to download", "info");
      return;
    }

    const downloadPromises = activeFiles.map((item) => {
      if (item.url) {
        downloadFile(item.url, getLastSegment(item.url));
      }
      if (item.templateOutputUrl) {
        downloadFile(
          item.templateOutputUrl,
          getLastSegment(item.templateOutputUrl)
        );
      }
    });

    await Promise.all(downloadPromises);
    toast("Selected social media asset(s) downloaded successfully", "success");
  };

  const selectStockImage = async (image: IStockImageVM): Promise<void> => {
    const response = await fetch(image.url);
    const imageBlob = await response.blob();
    const formData = new FormData();
    formData.append("files", imageBlob, "image.jpeg");

    const uploadResponse = await uploadFile({
      files: formData,
      isTemplateUsed: true,
      shouldRefetch: false,
    });

    const newFile = convertLibraryFileIntoSocialNetworkPostFile(
      uploadResponse.data.uploadedFiles[0]
    );
    insertMediaForAllSocialNetworkType(newFile as ISingleFIleVM, true, false);
    // let activeFiles = _.cloneDeep(getActiveSocialNetworkPost()?.files) || [];
    // activeFiles.push(newFile);

    // updateFilesUI({ files: activeFiles, sendRequest: true });
  };

  const handleGenerateImage = ({
    prompt,
    style,
  }: {
    prompt: string;
    style: ImageGenerationStyle;
  }): Promise<IBaseData<ISingleFIleVM>> => {
    return generateImageRequest({
      imageGenerationStyle: style,
      postId: postData!.id,
      prompt,
    });
  };

  return {
    selectUploadedMedia,
    handleGenerateImage,
    selectStockImage,
    selectMedia,
    insertMediaForAllSocialNetworkType,
    downloadMediasFromActiveSocialNetworkType,
  };
};
