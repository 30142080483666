import React, { Dispatch, SetStateAction } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import SpeechRecognition from "react-speech-recognition";
import Brief from "./Tabs/Brief";
import Channels from "./Tabs/Channels";
import Media from "./Tabs/Media";
import Frames from "./Tabs/Frames";
import PublishedTab from "./Tabs/PublishedTab";
import CreationHeader from "./CreationHeader";
import SButton from "../../design-system/SButton";
import { faMinus, faX } from "@fortawesome/pro-regular-svg-icons";
import { useCreationStore } from "../../store/creationStore";
import SBadge from "../../design-system/SBadge";

interface MobileSectionsDrawerProps {
  setIsUpdatedByCopilot: Dispatch<SetStateAction<boolean>>;
  onCreationClose: () => void;
}

const MobileSectionsDrawer = ({
  setIsUpdatedByCopilot,
  onCreationClose,
}: MobileSectionsDrawerProps) => {
  const {
    isMobileSectionsDrawerOpen,
    setIsMobileSectionsDrawerOpen,
    activeTab,
  } = useCreationStore();
  return (
    <>
      <CreationHeader
        onClose={async () => {
          await onCreationClose();
        }}
      />
      <Drawer
        open={isMobileSectionsDrawerOpen}
        onClose={async () => {
          setIsMobileSectionsDrawerOpen(false);
        }}
        direction="bottom"
        className="min-h-[94dvh] rounded-t-[24px] z-[1001]"
        lockBackgroundScroll={true}
      >
        <div
          className={"flex items-center justify-between px-[16px] py-[12px]"}
        >
          <p className="text-lg-m">
            {activeTab === "brief" && "Creation"}
            {activeTab === "accounts" && "Accounts"}
            {activeTab === "media" && "Assets"}
            {activeTab === "elements" && (
              <div className="flex items-center gap-2">
                Templates{" "}
                <SBadge size="sm" hasBackground={false} className="shadow-none">
                  Beta
                </SBadge>
              </div>
            )}
            {activeTab === "published" && "Published"}
          </p>
          <SButton
            icon={faMinus}
            type={"secondaryGray"}
            onClick={() => {
              setIsMobileSectionsDrawerOpen(false);
            }}
            className={"bg-base-white text-gray-600"}
          />
        </div>
        <div className={"h-[80dvh] w-full"}>
          {activeTab === "brief" && (
            <Brief setIsUpdatedByCopilot={setIsUpdatedByCopilot} />
          )}
          {activeTab === "accounts" && <Channels />}
          {activeTab === "media" && <Media />}
          {activeTab === "elements" && <Frames />}
          {activeTab === "published" && <PublishedTab />}
        </div>
      </Drawer>
    </>
  );
};

export default MobileSectionsDrawer;
