import { SocialNetworkType } from "../../utils/enums/Enums";
import SButton from "../../design-system/SButton";
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faReddit,
  faSlack,
  faTelegram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Tooltip } from "react-tooltip";
import LettersAvatar from "../utils/LettersAvatar";
import SToggle from "../../design-system/SToggle";

export interface IPlaformPageItem {
  id: number;
  title: string;
  logo: string;
  socialNetworkType: SocialNetworkType;
}

interface IPlatform {
  socialNetworkType: SocialNetworkType;
  pages: IPlaformPageItem[];
  className?: string;
  handleConnect: () => void;
  isLoading: SocialNetworkType | null;
  handleDelete: (id: number) => void;
  isActive?: boolean;
  setIsActive?: (p: boolean) => void;
}

function Platform({
  handleDelete,
  isLoading,
  className,
  handleConnect,
  socialNetworkType,
  pages,
  isActive,
  setIsActive,
}: IPlatform) {
  return (
    <div
      className={`${className} w-full p-[16px] border-[1px] border-graycool-100 shadow-xs rounded-[8px] bg-base-white`}
    >
      <div className="flex justify-between items-center pb-[16px] border-graycool-100 border-b-[1px]">
        <div className="flex gap-[16px] items-center">
          {socialNetworkType === SocialNetworkType.FACEBOOK && (
            <>
              <FontAwesomeIcon
                icon={faFacebook}
                color="#1877F2"
                fontSize={"24px"}
              />
              <p className="text-md-sb text-gray-700">Facebook</p>
            </>
          )}
          {socialNetworkType === SocialNetworkType.INSTAGRAM && (
            <>
              <FontAwesomeIcon
                icon={faInstagram}
                color="#D62976"
                fontSize={"24px"}
              />
              <p className="text-md-sb text-gray-700">Instagram</p>
            </>
          )}
          {socialNetworkType === SocialNetworkType.TWITTER && (
            <>
              <FontAwesomeIcon
                icon={faXTwitter}
                color="#000000"
                fontSize={"24px"}
              />
              <p className="text-md-sb text-gray-700">X (Twitter)</p>
            </>
          )}
          {socialNetworkType === SocialNetworkType.LINKEDIN && (
            <>
              <FontAwesomeIcon
                icon={faLinkedin}
                color="#0077B5"
                fontSize={"24px"}
              />
              <p className="text-md-sb text-gray-700">Linkedin</p>
            </>
          )}
          {socialNetworkType === SocialNetworkType.PINTEREST && (
            <>
              <FontAwesomeIcon
                icon={faPinterest}
                color="#E60023"
                fontSize={"24px"}
              />
              <p className="text-md-sb text-gray-700">Pinterest</p>
            </>
          )}
          {socialNetworkType === SocialNetworkType.REDDIT && (
            <>
              <FontAwesomeIcon
                icon={faReddit}
                color="#FF4500"
                fontSize={"24px"}
              />
              <p className="text-md-sb text-gray-700">Reddit</p>
            </>
          )}
          {socialNetworkType === SocialNetworkType.TELEGRAM && (
            <>
              <FontAwesomeIcon
                icon={faTelegram}
                color="#0088cc"
                fontSize={"24px"}
              />
              <p className="text-md-sb text-gray-700">Telegram</p>
            </>
          )}
          {socialNetworkType === SocialNetworkType.SLACK && (
            <>
              <FontAwesomeIcon
                icon={faSlack}
                color="#4A154B"
                fontSize={"24px"}
              />
              <p className="text-md-sb text-gray-700">Slack</p>
            </>
          )}
          {socialNetworkType === SocialNetworkType.DISCORD && (
            <>
              <FontAwesomeIcon
                icon={faDiscord}
                color="#7289DA"
                fontSize={"24px"}
              />
              <p className="text-md-sb text-gray-700">Discord</p>
            </>
          )}
        </div>
        <Tooltip id="brand-platforms-coming-soon">Coming soon</Tooltip>
        <div className="flex gap-[12px] items-center">
          <Tooltip id="brand-platforms-toggler">
            Generate content for this platform
          </Tooltip>
          {isActive !== undefined && setIsActive !== undefined && (
            <SToggle
              isToggled={isActive}
              setIsToggled={setIsActive}
              data-tooltip-id="brand-platforms-toggler"
            />
          )}
          
        </div>
      </div>
      <div className="flex flex-col mt-[12px] gap-[16px]">
        {pages && pages.length > 0 ? (
          pages.map((item) => (
            <div className="flex justify-between items-center">
              <div className="flex gap-[12px] items-center">
                {item.logo ? (
                  <img
                    alt={"logo"}
                    className="w-[32px] h-[32px] rounded-full"
                    src={item.logo}
                  />
                ) : (
                  <LettersAvatar
                    text={item?.title}
                    className="!w-[32px] !h-[32px] text-xs-m"
                  />
                )}
                <p className="text-sm-sb text-gray-800">{item.title}</p>
              </div>
              <SButton
                onClick={() => handleDelete(item.id)}
                icon={faTrash}
                type="tertiaryGray"
              />
            </div>
          ))
        ) : (
          <div className="w-full flex justify-center">
            <SButton
              data-tooltip-id={
                socialNetworkType === SocialNetworkType.REDDIT ||
                socialNetworkType === SocialNetworkType.TELEGRAM ||
                socialNetworkType === SocialNetworkType.SLACK ||
                socialNetworkType === SocialNetworkType.DISCORD
                  ? "brand-platforms-coming-soon"
                  : ""
              }
              isLoading={isLoading === socialNetworkType}
              type="tertiaryGray"
              lIcon={faAdd}
              onClick={() => {
                if (
                  socialNetworkType !== SocialNetworkType.REDDIT &&
                  socialNetworkType !== SocialNetworkType.TELEGRAM &&
                  socialNetworkType !== SocialNetworkType.SLACK &&
                  socialNetworkType !== SocialNetworkType.DISCORD
                ) {
                  handleConnect();
                }
              }}
            >
              Connect
            </SButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default Platform;
