import { Dispatch, SetStateAction } from "react";
import SSmallMenu from "../../../design-system/SSmallMenu";
import { FileType } from "../../../utils/enums/Enums";
import SButton from "../../../design-system/SButton";
import { faChevronDown, faClose } from "@fortawesome/pro-regular-svg-icons";
import { useFilterAssetType } from "../hooks/filters/useFilterAssetType";

export interface IAssetTypeFilterProps {
  className?: string;
  fileType: FileType | undefined;
  setFileType: Dispatch<SetStateAction<FileType | undefined>>;
}

export const AssetTypeFilter = ({
  className,
  fileType,
  setFileType,
}: IAssetTypeFilterProps) => {
  const { displayText, isOpen, setIsOpen, items, setFoldersAndFiles, setPage } =
    useFilterAssetType({ fileType, setFileType });

  return (
    <div className={`${className} flex items-center`}>
      <SButton
        className={`relative z-10 capitalize ${fileType !== undefined && "rounded-r-none"}`}
        onClick={() => setIsOpen((val) => !val)}
        type={fileType === undefined ? "secondaryGray" : "secondaryColor"}
        rIcon={faChevronDown}
        id="file_type_dropdown_button"
      >
        {displayText()}
        <SSmallMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={[items]}
          excludedId="file_type_dropdown_button"
        />
      </SButton>
      {fileType !== undefined && (
        <SButton
          onClick={() => {
            setPage(1);
            setFoldersAndFiles([]);
            setFileType(undefined);
          }}
          className="rounded-l-none border-l-0"
          type="secondaryColor"
          icon={faClose}
        />
      )}
    </div>
  );
};
