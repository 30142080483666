import { ITabItem } from "../../../design-system/STertiaryTabs";

export const tabItems: ITabItem[] = [
  {
    index: 0,
    text: "All",
  },
  {
    index: 1,
    text: "Drafts",
  },
  {
    index: 2,
    text: "Published",
  },
  {
    index: 4,
    text: "Scheduled",
  },
  {
    index: 3,
    text: "Reminders",
  },
];
