import ImageSelectorSidebar from "./Frames/ImageSelectorSidebar";
import SelectedFrameOptions from "./Frames/SelectedFrameOptions";
import FramesList from "./Frames/FramesList";
import { useFramesStore } from "../../../store/framesStore";
import { useTemplateRenderer } from "../hooks/useTemplateRenderer";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
import { isVideo } from "../../Library/hooks/getFileType";
function Frames() {
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const { selectedImage, activeFrame } = useFramesStore();
  useTemplateRenderer();

  return (
    <div style={{ height: "inherit" }} className="flex">
      {getActiveSocialNetworkPost() &&
        getActiveSocialNetworkPost()!.files.filter(
          (item) => !isVideo(item?.url || "")
        ).length > 0 && <ImageSelectorSidebar />}
      <div className="bg-base-white w-full overflow-auto flex flex-col">
        {(selectedImage?.templateOutputUrl || activeFrame) && (
          <SelectedFrameOptions />
        )}
        <FramesList />
      </div>
    </div>
  );
}

export default Frames;
