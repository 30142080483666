import { ReactNode } from "react";
import { ISingleNotification } from "../../utils/interfaces/INotifications";
import { formatDistanceToNowStrict } from "date-fns/formatDistanceToNowStrict";
import { parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { NotificationType } from "../../utils/enums/Enums";
import { usePutAllNotifications } from "../../api/notifications/putAll";
//@ts-ignore
import logo from "../../assets/images/logos/stori-s.svg";
import useNotificationSocketStore from "../../store/notificationsStore";

const NotificationsInHeaderItem = ({
  notification,
  message,
  handleCancel,
  handleSubmit,
  setIsNotificationsOpen,
}: {
  notification: ISingleNotification;
  message: ReactNode;
  handleSubmit?: (e: any) => void;
  handleCancel?: (e: any) => void;
  setIsNotificationsOpen?: (open: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { mutateAsync: readAllNotifications } = usePutAllNotifications();
  const ago = formatDistanceToNowStrict(
    parseISO(notification.created.toString())
  );
  const { setNotifications } = useNotificationSocketStore();
  return (
    <div
      className={
        "relative flex gap-[12px] py-[11px] border-b border-b-gray-100 cursor-pointer"
      }
      onClick={async () => {
        await readAllNotifications()
          .then((res) => {
            navigate("/notifications");
            setNotifications([]);
            setIsNotificationsOpen && setIsNotificationsOpen(false);
          })
          .catch((err) => {
            setIsNotificationsOpen && setIsNotificationsOpen(false);
          });
      }}
    >
      <img
        src={
          notification.type === NotificationType.SystemNotification
            ? logo
            : notification.senderPhotoUrl
        }
        alt={`Profile ${notification.senderName}`}
        className={"rounded-full w-[48px] h-[48px] bg-gray-200 object-cover"}
      />
      <div>
        <div className={"flex gap-[8px]"}>
          {notification.type !== NotificationType.SystemNotification && (
            <p className={"text-sm-m text-gray-700"}>
              {notification.senderName}
            </p>
          )}
          <p className={"text-xs text-gray-600"}>{ago} ago</p>
        </div>
        {notification.type === NotificationType.SystemNotification ? (
          <p
            className="mt-[4px] text-sm text-gray-600"
            dangerouslySetInnerHTML={{
              __html: notification.customProperty.message || "",
            }}
          />
        ) : (
          <p className={"text-sm text-gray-600"}>{message}</p>
        )}
        <div className={"text-sm-m mt-1 flex items-center gap-4"}>
          {notification.type === NotificationType.INVITATION && (
            <>
              <p onClick={handleSubmit} className="text-brand-600">
                Accept
              </p>
              <p onClick={handleCancel} className="text-gray-600">
                Reject
              </p>
            </>
          )}
        </div>
      </div>
      {!notification.isRead && (
        <div
          className={
            "w-[10px] h-[10px] absolute right-4 rounded-full bg-success-500"
          }
        ></div>
      )}
    </div>
  );
};

export default NotificationsInHeaderItem;
