import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import { useCreationStore } from "../../../store/creationStore";
import SBadge from "../../../design-system/SBadge";
import {
  faComment,
  faSparkles,
  faThumbsUp,
} from "@fortawesome/pro-regular-svg-icons";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
import { useUpdatePost } from "../hooks/useUpdatePost";
import ImagesGrid from "../Tabs/Media/ImagesGrid";
import { isVideo } from "../../Library/hooks/getFileType";
import ExpandedImages from "../Tabs/Media/ExpandedImages";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import CopilotStateChangeAgreement from "../modals/CopilotStateChangeAgreement";
import { useAccountStore } from "../../../store/accountStore";
import { OrganizationUserRole } from "../../../utils/enums/Enums";
import { useTextChangeAnimation } from "../hooks/useTextChangeAnimation";
import { CharacterCounter } from "./CharacterCounter";
import FramesPreview from "./FramesPreview";
import { useGetWindowWidth } from "../../../hooks/useGetWindowWidth";
import PublishedTab from "../Tabs/PublishedTab";
import CommentsModal from "../CommentsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialScore from "../SocialScore";

interface ICreationPreview {
  className?: string;
  isHovered: boolean;
  isUpdatedByCopilot: boolean;
  setIsUpdatedByCopilot: (value: boolean) => void;
}

function DefaultPreview({
  isHovered,
  className,
  isUpdatedByCopilot,
  setIsUpdatedByCopilot,
}: ICreationPreview) {
  const {
    getActiveSocialNetworkPost,
    isPostPublishedOrScheduled,
    postData,
    isPostPending,
    activeSoc,
  } = useCreationHelperFunctions();
  const { updateTextUI } = useUpdatePost();
  const { data: currentBrand } = useGetCurrentBrand();
  const [isTextEditing, setIsTextEditing] = useState(false);
  const [isExpandedImagesOpen, setIsExpandedImagesOpen] = useState(false);
  const { personalInfo } = useAccountStore();
  const { activeTab, setActiveTab, setIsMobileSectionsDrawerOpen } =
    useCreationStore();

  const { insertAnimation } = useTextChangeAnimation({
    getActiveSocialNetworkPost,
    postData,
    isPostPending,
    isUpdatedByCopilot,
    setIsUpdatedByCopilot,
  });
  const [text, setText] = useState(getActiveSocialNetworkPost()?.text);
  useEffect(() => {
    setText(getActiveSocialNetworkPost()?.text?.replace(/\n/g, ""));
  }, [getActiveSocialNetworkPost()?.text, setText]);
  return (
    <>
      {isExpandedImagesOpen && (
        <ExpandedImages
          isOpen={isExpandedImagesOpen}
          onClose={() => setIsExpandedImagesOpen(false)}
        />
      )}
      <div
        className={`${className} max-w-[393px] w-full relative rounded-[16px] bg-transparent max-md:scale-90`}
        style={{ boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.04)" }}
      >
        <div className="relative flex items-center gap-[12px] pt-[16px] pb-[16px] px-[20px] rounded-t-[16px] bg-base-white border-t border-l border-r border-gray-200">
          <img
            className={`w-[40px] h-[40px] border-1 border-gray-50 rounded-full object-contain`}
            src={currentBrand?.data?.logo}
            alt="brand-logo"
          />
          <div>
            <p className="text-sm-sb text-gray-900">
              {currentBrand?.data?.name}
            </p>
            <p className="text-xs text-gray-600">10h</p>
          </div>
          <div className="ml-auto self-start">
            <SocialScore />
          </div>
        </div>
        <div
          className={`${isHovered && "my-[12px] border-t border-b"} 
          transition-all pt-[16px] bg-base-white border-l border-r border-gray-200  text-sm text-gray-700 px-[20px] 
        hover:border-brand-300 hover:border-[1px]
        pb-[16px] min-h-[56px] h-fit w-full`}
        >
          <pre
            onInput={(e: any) => {
              const text = e.target.innerText.replace(/\n/g, ""); // remove line breaks
              setText(text);
            }}
            className={`w-full inline duration-1000 ease-out ${insertAnimation && "bg-brand-300"} whitespace-pre-wrap break-words ${isPostPublishedOrScheduled() && "pointer-events-none"}
            relative 
            outline-none`}
            onClick={() => {
              if (!getActiveSocialNetworkPost()?.text) {
                setIsTextEditing(true);
              }
            }}
            onBlur={(e) => {
              if (
                e.target.innerText.length === 0
                // !isCopilotStateChangeAgreementOpen
              ) {
                setIsTextEditing(false);
              }
              if (getActiveSocialNetworkPost()?.text !== e.target.innerText) {
                updateTextUI({
                  text: e.target.innerText,
                  sendRequest: true,
                });
              }
            }}
            onPaste={(event) => {
              event.preventDefault();
              const text = event.clipboardData.getData("text/plain");
              document.execCommand("insertText", false, text);
            }}
            contentEditable={
              personalInfo?.userRole !== OrganizationUserRole.VIEWER
            }
          >
            {getActiveSocialNetworkPost()?.text || isTextEditing ? (
              getActiveSocialNetworkPost()?.text
            ) : (
              <div className={"flex gap-[8px]"}>
                <p className="text-sm underline text-graymodern-400">
                  Click here to write or
                </p>
                <SBadge
                  color="purple"
                  size="sm"
                  icon={faSparkles}
                  className={"cursor-pointer"}
                  onClick={() => {
                    activeTab !== "brief" &&
                      setActiveTab("brief") &&
                      setIsMobileSectionsDrawerOpen(true);
                  }}
                >
                  Ask Jason
                </SBadge>
              </div>
            )}
          </pre>
          {/* {activeSoc && (
            <CharacterCounter activeSoc={activeSoc} text={text || ""} />
          )} */}
        </div>{" "}
        {/* </div> */}
        {activeTab === "elements" ? (
          <FramesPreview />
        ) : (
          getActiveSocialNetworkPost() &&
          getActiveSocialNetworkPost()!.files.length > 0 && (
            <div
              className={`
          ${isPostPublishedOrScheduled() && "pointer-events-none"}
          transition-all w-full aspect-square border-[2px] cursor-pointer border-transparent hover:border-brand-300`}
            >
              {getActiveSocialNetworkPost() &&
                getActiveSocialNetworkPost()!.files.length > 0 &&
                (isVideo(getActiveSocialNetworkPost()!.files[0].url || "") ? (
                  <video
                    onClick={(e) => {
                      e.stopPropagation();
                      personalInfo?.userRole !== OrganizationUserRole.VIEWER &&
                        setIsExpandedImagesOpen(true);
                    }}
                    key={getActiveSocialNetworkPost()!.files[0].url}
                    className="w-full h-full object-contain"
                    controls={true}
                  >
                    <source src={getActiveSocialNetworkPost()!.files[0].url} />
                  </video>
                ) : (
                  <ImagesGrid
                    onClick={() =>
                      personalInfo?.userRole !== OrganizationUserRole.VIEWER &&
                      setIsExpandedImagesOpen(true)
                    }
                    images={getActiveSocialNetworkPost()!.files.map((item) =>
                      item.templateOutputUrl
                        ? item.templateOutputUrl
                        : item.url!
                    )}
                  />
                ))}
            </div>
          )
        )}
        {/* <img
        onClick={() => setActiveTab("media")}
        className={`transition-all w-full aspect-square border-[2px] cursor-pointer border-transparent hover:border-success-300`}
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRem_c2UVBTZckm9rh1AFynK8zXsEGpEsCaZg&s"
        alt=""
      /> */}
        <div className="bg-base-white border-x border-gray-200">
          <div
            className={`w-full flex justify-between items-center py-4 px-5 transition-all  ${isHovered && "mt-[12px]"}`}
          >
            <div className={"flex gap-[10px] items-center"}>
              <FontAwesomeIcon icon={faThumbsUp} className="text-gray-400" />
              <div className={"flex gap-[10px] items-center"}>
                <FontAwesomeIcon icon={faComment} className="text-gray-400" />
              </div>
            </div>
            <p className="text-xs text-gray-500">
              {postData?.comments && postData.comments.length} comments
            </p>
          </div>
          {postData?.comments && postData.comments.length >= 1 && (
            <p className="p-1 pl-4 text-xs text-gray-400">Member Comments</p>
          )}
        </div>
        <CommentsModal />
      </div>
    </>
  );
}

export default DefaultPreview;
