import { CardType } from "../../../pages/Library";

export const determineCardType = (extension?: string) => {
  extension = extension?.toLowerCase();
  let type: CardType = "Folder";
  type =
    extension === ".jpg" ||
    extension === ".png" ||
    extension === ".jpeg" ||
    extension === ".gif"
      ? "Image"
      : type;
  type =
    extension === ".mp4" ||
    extension === ".mov" ||
    extension === ".avi" ||
    extension === ".webm"
      ? "Video"
      : type;
  type = extension === ".pdf" ? "PDF" : type;
  type = extension === ".docx" || extension === ".doc" ? "Word" : type;
  type = extension === ".txt" ? "Text" : type;
  return type;
};
