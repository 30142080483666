import { useRef, useState } from "react";
import LibraryTab from "./Media/LibraryTab";
import { useCreationStore } from "../../../store/creationStore";
import HelperInput from "./Media/HelperInput/HelperInput";
import { LibraryDropdown } from "../../Library/LibraryDropdown";
// @ts-ignore
import DropboxChooser from "react-dropbox-chooser";
import { useUploadAssets } from "../../Library/hooks/useUploadAssets";
import { useLibraryDropbox } from "../../Library/hooks/useLibraryDropbox";
import { useFolderHistory } from "../../Library/hooks/useFolderHistory";
import { LibraryCreateFolderModal } from "../../Library/LibraryCreateFolderModal";
import { useCreationLibraryHelperFunctions } from "./Media/hooks/useCreationLibraryHelperFunctions";
import { toast } from "../../../hooks/toast";
import { useGridCardActions } from "../../Library/hooks/useGridCardActions";
import { ConfirmDelete } from "../../Library/ConfirmDelete";
import { deleteFileOrFolder } from "../../Library/functions/deleteFileOrFolder";
import { files } from "jszip";
import { LibraryLoadingFile } from "../../Library/LibraryLoadingFile";
import { LibraryLoadingFilesModal } from "../../Library/LibraryLoadingFilesModal";
function Media() {
  const wrapperRef = useRef<any>();
  const { librarySearchValue } = useCreationStore();
  const { parentFolderId, isFetching } = useFolderHistory({
    wrapperRef,
    searchValue: librarySearchValue.length > 0 ? librarySearchValue : undefined,
  });
  const [folderName, setFolderName] = useState("");
  const { selectUploadedMedia } = useCreationLibraryHelperFunctions();

  const {
    selectFile,
    createFolder,
    isPending,
    createFile,
    files: assetFiles,
    setFiles: setAssetFiles,
  } = useUploadAssets({
    parentFolderId,
    uploadFileAdditionalFunction: (res) => selectUploadedMedia(res),
  });
  const { dropboxRef, onDropboxSelectSubmited, setDropboxFiles, dropboxFiles } =
    useLibraryDropbox({
      createFile,
      parentFolderId,
      isPending,
      uploadFileAdditionalFunction: (res) => selectUploadedMedia(res),
    });

  const [openModal, setOpenModal] = useState(false);
  const {
    openRename,
    setOpenRename,
    updateFolder,
    setValue,
    value,
    handleDownload,
    deleteFile,
    deleteFolder,
    setOpenConfirmation,
    openConfirmation,
    handleDelete,
    handleMoveInto,
    handleMoveOut,
    handleFolderDownload,
    handleRename,
    renameAsset,
    deleteAsset,
  } = useGridCardActions();

  return (
    <>
      {openModal && (
        <LibraryCreateFolderModal
          openModal={openModal}
          action="Create"
          type="Folder"
          setOpenModal={() => setOpenModal(false)}
          name={folderName}
          setName={(e) => setFolderName(e.target!.value)}
          onSave={() => {
            createFolder({ name: folderName, parentFolderId }).catch((err) => {
              toast(
                err?.response?.data?.message ||
                  "An error occurred while creating the folder"
              );
            });
            setOpenModal(false);
            setFolderName("");
          }}
        />
      )}

      {openRename && (
        <LibraryCreateFolderModal
          action="Rename"
          type={"Folder"}
          openModal={openRename}
          setOpenModal={() => setOpenRename(false)}
          name={value}
          setName={(e) => setValue(e.target!.value)}
          onSave={() => {
            updateFolder({
              folderName: value,
              id: renameAsset?.id || 0,
            })
              .then(() => {
                toast(`Folder renamed successfully`, "success");
              })
              .catch(() => {
                toast(`Folder rename failed`, "error");
              });
            setOpenRename(false);
            setValue("");
          }}
        />
      )}

      {openConfirmation && (
        <ConfirmDelete
          title="Are you sure you want to delete this asset?"
          setOpenModal={() => setOpenConfirmation(false)}
          openModal={openConfirmation}
          onCancel={() => setOpenConfirmation(false)}
          onConfirm={() =>
            deleteFileOrFolder({
              deleteAsset,
              deleteFolder,
              deleteFile,
              setOpenConfirmation,
            })
          }
        />
      )}

      <div style={{ height: "inherit" }} className="relative">
        <div className="min-h-[calc(46px+17px)] flex justify-start md:items-center border-b-[1px] border-b-graycool-100 pb-7">
          <HelperInput
            setOpenModal={setOpenModal}
            dropboxRef={dropboxRef}
            selectFile={selectFile}
          />
          <DropboxChooser
            appKey="js3i72s02ssirnl"
            success={(files: any) => {
              onDropboxSelectSubmited(files);
            }}
            extensions={[
              ".jpg",
              ".jpeg",
              ".png",
              ".mp4",
              ".mov",
              ".avi",
              ".wmv",
              ".flv",
              ".webm",
              ".pdf",
              ".docx",
              ".doc",
              ".xlsx",
              ".xls",
              ".txt",
            ]}
            multiselect={true}
          >
            <input ref={dropboxRef} className="hidden" />
          </DropboxChooser>
        </div>
        <LibraryLoadingFilesModal
          setDropboxFiles={setDropboxFiles}
          setFiles={setAssetFiles}
          isPending={isPending}
        >
          {assetFiles?.map((file) => {
            return (
              <LibraryLoadingFile
                url={URL.createObjectURL(file)}
                type={file.type}
                name={file.name}
                size={file.size}
                isPending={isPending}
              />
            );
          })}
          {dropboxFiles?.map((file: any) => {
            return (
              <LibraryLoadingFile
                url={file.thumbnailLink}
                type="image/jpeg"
                icon={file.icon}
                name={file.name}
                size={file.bytes}
                isPending={isPending}
              />
            );
          })}
        </LibraryLoadingFilesModal>
        <LibraryTab
          wrapperRef={wrapperRef}
          isFetching={isFetching}
          handleDelete={handleDelete}
          handleDownload={handleDownload}
          handleFolderDownload={handleFolderDownload}
          handleMoveInto={handleMoveInto}
          handleRename={handleRename}
          handleMoveOut={handleMoveOut}
          librarySearchValue={librarySearchValue}
          setOpenModal={setOpenModal}
          dropboxRef={dropboxRef}
          selectFile={selectFile}
        />
      </div>
    </>
  );
}

export default Media;
