import { useEffect, useState } from "react";
import PageHeading from "../components/PageHeading";
import { useNavigate } from "react-router-dom";
import PostList from "../components/content/PostList";
import useScrollStatus from "../hooks/useScrollStatus";
import { ISinglePostVM } from "../utils/interfaces/IPost";
import { useGetAllPosts } from "../api/posts/getAll";
import { useContentStore } from "../store/contentStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { useCreationHelperFunctions } from "../components/creation/hooks/useCreationHelperFunctions";
import { useAccountStore } from "../store/accountStore";
import { useGetWorkspaceUsage } from "../api/workspaces/limits/get";
import { useSetDefaultSection } from "../components/content/hooks/useSetDefaultSection";
import { tabItems } from "../components/content/utils/tabItems";
import WhichSocialNetworksSelector from "../components/utils/WhichSocialNetworksSelector";
import { ContentIdea } from "../components/content/ContentIdea";
import { useBrandStore } from "../store/brandStore";
import { OrganizationUserRole } from "../utils/enums/Enums";
import { EmptyContentState } from "../components/creation/EmptyState/EmptyContentState";
import { useGetCurrentBrand } from "../api/brand/current/get";

function Content() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const { posts, setPosts, page, setPage } = useContentStore();
  const { data, isFetching } = useGetAllPosts({
    page,
    pageSize: 30,
    postStatus: selected === 0 ? undefined : selected,
    keyword: searchValue.length === 0 ? undefined : searchValue,
  });
  const [totalCount, setTotalCount] = useState(
    selected === 0 && data?.data.totalCount
  );
  useEffect(() => {
    if (selected === 0) {
      setTotalCount(data?.data.totalCount);
    }
  }, [data]);

  const { isScrolledToBottom, isFullyOnScreen } = useScrollStatus(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const { data: currentBrand, isError: isCurrentBrandError } =
    useGetCurrentBrand();

  useEffect(() => {
    if (selected !== 0) {
      setSelected(0);
    }
  }, [currentBrand?.data.id]);

  useEffect(() => {
    if (initialLoadComplete && !isFetching && data && data.data.showNext) {
      if (isScrolledToBottom || isFullyOnScreen) {
        setPage(page + 1);
      }
    }
  }, [isScrolledToBottom, initialLoadComplete, data, isFullyOnScreen]);

  useEffect(() => {
    if (data && !isFetching) {
      setPosts([
        ...posts.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        ),
        ...data.data.posts,
      ]);
      if (!initialLoadComplete) {
        setInitialLoadComplete(true);
      }
    }
  }, [data]);

  useEffect(() => {
    setPosts([]);
    setPage(1);
  }, [searchValue]);
  const { handleOpenPost } = useCreationHelperFunctions();
  const { personalInfo } = useAccountStore();
  const { data: workspaceUsage } = useGetWorkspaceUsage();

  useSetDefaultSection({ setPage, setPosts, setSelected });
  const [
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
  ] = useState(false);
  return (
    <>
      {isWhichSocialNetworksSelectorOpen && (
        <WhichSocialNetworksSelector
          saveAllOnClose={true}
          isOpen={isWhichSocialNetworksSelectorOpen}
          onClose={() => setIsWhichSocialNetworksSelectorOpen(false)}
        />
      )}
      <div className="w-full py-[24px]">
        <PageHeading
          title="Content"
          subtitle="Manage your posts and content."
          withInput={true}
          inputProps={{
            placeholder: "Search...",
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value),
            rightAdditionalContent: (
              <div
                className={`${searchValue.length > 0 ? "block" : "hidden"}`}
                onClick={() => setSearchValue("")}
              >
                <FontAwesomeIcon
                  icon={faX}
                  fontSize={"14px"}
                  className="text-gray-700 cursor-pointer"
                />
              </div>
            ),
          }}
          tabsProps={{
            items: tabItems,
            selected: selected,
            setSelected: (i) => {
              setPosts([]);
              setPage(1);
              setSelected(i);
            },
          }}
        />
        {totalCount === 0 && !isFetching && <EmptyContentState />}
        {currentBrand?.data.isBrandDataGenerated &&
          workspaceUsage?.data.canUserAddPosts &&
          posts.length > 0 &&
          personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
            <ContentIdea
              setIsWhichSocialNetworksSelectorOpen={
                setIsWhichSocialNetworksSelectorOpen
              }
            />
          )}
        <PostList
          handleOpenPost={(p: ISinglePostVM) => {
            handleOpenPost({
              postId: p.id,
              onSocialNetworkSelectorOpen: () =>
                setIsWhichSocialNetworksSelectorOpen(true),
            });
          }}
          handleCreateNew={() =>
            handleOpenPost({
              onSocialNetworkSelectorOpen: () =>
                setIsWhichSocialNetworksSelectorOpen(true),
            })
          }
          posts={posts}
          totalCount={totalCount || 0}
          searchValue={searchValue}
          isLoading={isFetching}
          selected={selected}
        />
      </div>
    </>
  );
}

export default Content;
