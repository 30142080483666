import React, { useState, useEffect } from "react";
import SSelectOpener from "../../design-system/SSelectOpener";
import SSelectInputRadioDropdown, {
  ISSSelectRadioInputDropdown,
} from "../../design-system/SSelectInputRadioDropdown";
import { FieldToolTip } from "./FieldToolTip";

interface IFieldSelector {
  fieldName: string;
  toolTipContent?: string;
  selectorProps: ISSSelectRadioInputDropdown; // Make sure selectorProps is required and properly typed
  className?: string;
}

function FieldSelector({
  fieldName,
  selectorProps,
  className,
  toolTipContent,
}: IFieldSelector) {
  const [selectedLanguageText, setSelectedLanguageText] = useState(
    selectorProps.defaultSelect ? selectorProps.defaultSelect : ""
  );

  useEffect(() => {
    const selectedLanguage = selectorProps?.items.find(
      (item) => item.index === selectorProps?.selected
    );
    if (selectedLanguage) {
      setSelectedLanguageText(selectedLanguage.text);
    }
  }, [selectorProps?.selected, selectorProps?.items]);

  return (
    <div
      className={`${className} flex items-center justify-between py-[20px] border-b-[1px] border-gray-200 max-md:flex-col max-md:items-start max-md:gap-[10px] max-md:py-[12px]`}
    >
      <div className="flex-none relative w-[140px]">
        <span className="text-left text-sm-sb text-gray-700">{fieldName}</span>
        <FieldToolTip toolTipContent={toolTipContent} />
      </div>
      <div className="flex-grow flex justify-center items-center w-full flex-col relative max-md:items-start">
        <SSelectOpener
          image={selectorProps?.image}
          id={selectorProps?.id}
          onOpen={() => selectorProps?.setIsOpen(!selectorProps?.isOpen)}
          text={selectedLanguageText}
          className="w-1/2 max-md:w-[68%]"
        />
        <SSelectInputRadioDropdown {...selectorProps} />
      </div>
      <div className="flex-none w-1/4 max-md:hidden"></div>
    </div>
  );
}

export default FieldSelector;
