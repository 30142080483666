import React, { useState } from "react";
import SModalBase from "../../../../design-system/SModalBase";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faExpand, faX } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../design-system/SButton";
import { useCreationLibraryHelperFunctions } from "./hooks/useCreationLibraryHelperFunctions";
import { ISingleFIleVM } from "../../../../utils/interfaces/IAssets";
import ExpandedImageModal from "../../../utils/ExpandedImageModal";
import { isVideo } from "../../../Library/hooks/getFileType";

function ExpandedImages({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { insertMediaForAllSocialNetworkType,downloadMediasFromActiveSocialNetworkType } =
    useCreationLibraryHelperFunctions();
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  return (
    <>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
        />
      )}
      <SModalBase
        dialogPanelClassName={"max-w-[600px] max-md:max-w-[350px] w-full"}
        isOpen={isOpen}
        onClose={onClose}
        showX={false}
      >
        <div>
          <SButton
            onClick={onClose}
            icon={faX}
            size="sm"
            type="secondaryGray"
            className="absolute left-[calc(100%+6px)] top-0"
          />
          <SButton
            title='Download all'  
            onClick={downloadMediasFromActiveSocialNetworkType}
            icon={faDownload}
            size="sm"
            type="secondaryGray"
            className="absolute left-[calc(100%+6px)] top-11"
          />
        </div>
        <div className="flex flex-wrap max-h-[80dvh] overflow-auto gap-[30px] justify-center h-full w-full">
          {getActiveSocialNetworkPost() &&
            getActiveSocialNetworkPost()?.files.map((item) => (
              <div
                className="w-[40%] aspect-square mx-[12px] relative rounded-[8px] overflow-hidden cursor-pointer"
                onClick={(e) => {
                  if (!isVideo((item.templateOutputUrl || item.url)!)) {
                    setExpandedImage((item.templateOutputUrl || item.url)!);
                  }
                }}
              >
                <div className="absolute top-[12px] right-[12px] flex flex-col gap-[12px]">
                  <SButton
                    icon={faX}
                    type="secondaryGray"
                    className="opacity-80 relative z-[100] rounded-full w-[32px] !h-[32px]"
                    iconProps={{
                      className: "text-[14px]",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      insertMediaForAllSocialNetworkType(item as ISingleFIleVM);
                      if (getActiveSocialNetworkPost()?.files.length === 1) {
                        onClose();
                      }
                    }}
                  />
                </div>
                {isVideo((item.templateOutputUrl || item.url)!) ? (
                  <video
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="w-full h-full object-cover rounded-lg"
                    controls
                  >
                    <source
                      src={item.templateOutputUrl || item.url}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    className="w-full h-full object-cover"
                    src={item.templateOutputUrl || item.thumbnailMediumUrl}
                    alt="image"
                  />
                )}
              </div>
            ))}
        </div>
      </SModalBase>
    </>
  );
}

export default ExpandedImages;
