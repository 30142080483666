import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IBrandCreationData, Step } from "../../pages/CreateBrand";
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircle1,
  faCircle2,
  faCircle3,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";
import { Tooltip } from "react-tooltip";
const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

function Stepper({
  handleCreatBrand,
  isBrandCreating,
  ready,
  data,
  steps,
  currentStep,
  setCurrentStep,
}: {
  handleCreatBrand: () => void;
  isBrandCreating: boolean;
  ready: boolean;
  data: IBrandCreationData;
  steps: Step[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
}) {
  return (
    <div className="flex justify-center items-center gap-[16px]">
      <SButton
        icon={faChevronLeft}
        type="secondaryGray"
        disabled={currentStep === 0}
        onClick={() => {
          if (currentStep !== 0) setCurrentStep(currentStep - 1);
        }}
      />
      {!data.type && (
        <Tooltip
          style={{
            fontSize: "14px",
          }}
          id="tooltip-brand-creation-step-1"
          place="top"
          disableStyleInjection
        >
          Choose a brand type to generate a brand
        </Tooltip>
      )}
      {/* {!data.type && ( */}
      {/* <Tooltip
          style={{
            fontSize: "14px",
          }}
          id="tooltip-brand-creation-step-2"
          place="top"
          disableStyleInjection
        >
          Choose a brand type to generate a brand
        </Tooltip> */}
      {/* )} */}
      <div className="flex items-center">
        {steps.map((item, index) => (
          <div
            data-tooltip-id={`tooltip-brand-creation-step-${index}`}
            key={index}
            className={`${((!data.type && index !== 0) || (index === 2 && currentStep !== 2)) && "opacity-50"} flex items-center`}
          >
            <div
              onClick={() => {
                if (
                  currentStep !== 2 &&
                  index !== 2 &&
                  data.type &&
                  currentStep !== index
                )
                  setCurrentStep(index);
              }}
              style={{
                outline: "1px solid transparent",
                outlineOffset: "4px",
                zIndex: currentStep === index ? 10 : 1,
              }}
              className={`
            cursor-pointer max-h-[40px] flex items-center gap-[8px] px-[12px] py-[10px] bg-base-white border border-gray-200 rounded-[8px]
            ${currentStep === index ? "!outline-brand-200 !border-brand-600" : ""}
          `}
            >
              <FontAwesomeIcon
                className={`text-[20px] ${currentStep === index ? "text-brand-600" : "text-gray-400"}`}
                icon={
                  index === 0 ? faCircle1 : index === 1 ? faCircle2 : faCircle3
                }
              />
              <p
                className={`${currentStep === index ? "text-sm-sb text-gray-700" : "text-sm text-gray-600"} max-md:hidden`}
              >
                {item.name}
              </p>
            </div>
            {index < steps.length - 1 && (
              <div className="flex-grow h-[2px] relative z-0 w-[34px]">
                {currentStep === 0 && index === 0 && (
                  <div className="absolute left-0 h-full w-[4px] bg-white z-10"></div>
                )}
                {currentStep === 1 && index === 0 && (
                  <div className="absolute right-0 h-full w-[4px] bg-white z-10"></div>
                )}
                {currentStep === 1 && index === 1 && (
                  <div className="absolute left-0 h-full w-[4px] bg-white z-10"></div>
                )}
                {currentStep === 2 && index === 1 && (
                  <div className="absolute right-0 h-full w-[4px] bg-white z-10"></div>
                )}
                <div className="flex-grow h-full bg-gray-200"></div>
              </div>
            )}
          </div>
        ))}
      </div>
      {!data.type && (
        <Tooltip
          style={{
            fontSize: "14px",
          }}
          id="tooltip-brand-creation-next-step"
          place="top"
          disableStyleInjection
        >
          Choose a brand type to generate a brand
        </Tooltip>
      )}

      {!ready && (
        <SButton
          data-tooltip-id="tooltip-brand-creation-next-step"
          disabled={currentStep === steps.length - 1 || !data.type}
          onClick={() => {
            if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
          }}
          icon={faChevronRight}
          type="secondaryGray"
        />
      )}
    </div>
  );
}

export default Stepper;
