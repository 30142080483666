import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isVideo } from "./hooks/getFileType";

export interface ILibraryLoadingFileProps {
  name: string;
  size: number;
  type: string;
  url?: string;
  icon?: string;
  isPending: boolean;
}

export const LibraryLoadingFile = ({
  name,
  size,
  type,
  icon,
  url,
  isPending,
}: ILibraryLoadingFileProps) => {
  const imageOrVideo = type.split("/")[0];
  return (
    <div className="w-full rounded-lg border border-gray-200 flex gap-3 p-[16px]">
      <div className="w-[120px]">
        {imageOrVideo === "video" ? (
          <video width={120} className="h-full object-cover rounded-lg" muted>
            <source src={url} type="video/mp4" />
          </video>
        ) : (
          <>
            {url && (
              <img
                width={120}
                className="rounded-lg h-full object-cover"
                src={url}
                alt={name}
              />
            )}
            {!url && icon && (
              <img
                width={82}
                className="rounded-lg h-full object-cover"
                src={icon}
                alt={name}
              />
            )}
          </>
        )}
      </div>
      <div className="w-full flex flex-col gap-1">
        <div className="flex justify-between items-center">
          <p className="text-sm-m max-md:text-xs">{name}</p>
        </div>
        <p className="text-sm">{size / 1000} KB</p>
        {isPending && (
          <div className="w-full flex items-center gap-1">
            <FontAwesomeIcon
              fontSize={"14px"}
              color="#344054"
              icon={faSpinner}
              className="animate-spin"
            />
            <h1 className="text-gray-700 text-xs-m">Loading...</h1>
          </div>
        )}
        {!isPending && (
          <div className="w-full flex items-center gap-1">
            <FontAwesomeIcon fontSize={"14px"} color="#8a47e0" icon={faCheck} />
            <h1 className="text-brand-600 text-xs-m">Success!</h1>
          </div>
        )}
      </div>
    </div>
  );
};
