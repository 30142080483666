import { UseMutateAsyncFunction } from "react-query";
import { toast } from "../../../hooks/toast";
import { ISingleFIleVM } from "../../../utils/interfaces/IAssets";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISingleFolderVM } from "../../../utils/interfaces/IFolders";
import { IExpandedImage } from "../LibraryView";

export interface IDeleteFileOrFolderProps {
  deleteAsset: { id: number; type: string } | null;
  deleteFolder: UseMutateAsyncFunction<
    IBaseData<ISingleFolderVM>,
    Error,
    { id: number },
    unknown
  >;
  deleteFile: UseMutateAsyncFunction<
    IBaseData<ISingleFIleVM>,
    Error,
    { id: number },
    unknown
  >;
  setOpenConfirmation: (open: boolean) => void;
  setExpandedImage?: (image: IExpandedImage | null) => void;
}

export const deleteFileOrFolder = ({
  deleteAsset,
  deleteFolder,
  deleteFile,
  setOpenConfirmation,
  setExpandedImage,
}: IDeleteFileOrFolderProps) => {
  deleteAsset?.type === "File" &&
    deleteFile({ id: deleteAsset?.id || 0 })
      .then(() => {
        toast(`Asset deleted successfully`, "success");
      })
      .catch((err) => {
        toast(err.response.data.message, "error");
      });
  deleteAsset?.type === "Folder" &&
    deleteFolder({ id: deleteAsset?.id || 0 })
      .then(() => {
        toast(`Asset deleted successfully`, "success");
      })
      .catch((err) => {
        toast(err.response.data.message, "error");
      });
  setOpenConfirmation(false);
  setExpandedImage && setExpandedImage(null);
};
