import { Dispatch, SetStateAction } from "react";
import SButton from "../../../design-system/SButton";
import SSmallMenu from "../../../design-system/SSmallMenu";
import { faChevronDown, faClose } from "@fortawesome/pro-regular-svg-icons";

import { useFilterAssetSource } from "../hooks/filters/useFilterAssetSource";

export interface IAssetSourceFilterProps {
  className?: string;
  source: { generatedOnly: boolean; uploadedOnly: boolean };
  setSource: Dispatch<
    SetStateAction<{ generatedOnly: boolean; uploadedOnly: boolean }>
  >;
}

export const AssetSourceFilter = ({
  className,
  source,
  setSource,
}: IAssetSourceFilterProps) => {
  const { isOpen, setIsOpen, items, setFoldersAndFiles, setPage } =
    useFilterAssetSource({ source, setSource });
  return (
    <div className={`${className} flex items-center`}>
      <SButton
        className={`relative z-10 capitalize ${(source.generatedOnly || source.uploadedOnly) && "rounded-r-none"}`}
        onClick={() => setIsOpen((val) => !val)}
        type={
          !source.generatedOnly && !source.uploadedOnly
            ? "secondaryGray"
            : "secondaryColor"
        }
        rIcon={faChevronDown}
        id="file_source_dropdown_button"
      >
        {!source.generatedOnly && !source.uploadedOnly && "Source"}
        {source.generatedOnly && "Generated"}
        {source.uploadedOnly && "Uploaded"}
        <SSmallMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={[items]}
          excludedId="file_source_dropdown_button"
        />
      </SButton>
      {(source.generatedOnly || source.uploadedOnly) && (
        <SButton
          onClick={() => {
            setFoldersAndFiles([]);
            setPage(1);
            setSource({ generatedOnly: false, uploadedOnly: false });
          }}
          className="rounded-l-none border-l-0"
          type="secondaryColor"
          icon={faClose}
        />
      )}
    </div>
  );
};
