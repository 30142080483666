import SInput, { ISInputProps } from "../../design-system/SInput";
import React from "react";

interface IFieldInput {
  fieldName: string;
  inputProps?: ISInputProps;
  className?: string;
}

function FieldInput({ fieldName, inputProps, className }: IFieldInput) {
  const id = fieldName.replace(/\s+/g, "-").toLowerCase();

  return (
    <div
      className={`${className} flex items-center justify-between py-[20px] border-b-[1px] border-gray-200 max-md:flex-col max-md:items-start max-md:gap-[10px] max-md:py-[12px]`}
    >
      <div className="flex-none w-[140px]">
        <span className="text-left text-sm-sb text-gray-700">{fieldName}</span>
      </div>
      <div className="flex-grow flex justify-center max-md:w-[68%]">
        <SInput className="w-1/2 max-md:w-full" id={id} {...inputProps} />
      </div>
      <div className="flex-none w-1/4 max-md:hidden"></div>
    </div>
  );
}

export default FieldInput;
