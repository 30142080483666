import { useGetFramesGroups } from "../../../../api/frames/groups/get";
import { useFramesStore } from "../../../../store/framesStore";
import { useTemplateRendererHelperFunctions } from "../../hooks/useTemplateRendererHelperFunctions";
import { useGetFavouriteFrames } from "../../../../api/frames/favourites/get";
import FramesGroup from "./FramesGroup";

function FramesList() {
  const { data: frameGroups } = useGetFramesGroups();
  const { renderTemplate } = useTemplateRendererHelperFunctions();
  const { data: favouriteFrames } = useGetFavouriteFrames();
  const { selectedImage, whichFrameIsRendering, activeFrame } =
    useFramesStore();
  const isActive = (templateId: number) => {
    if (activeFrame) {
      if (templateId === activeFrame?.id) {
        return true;
      }
    }
    if (selectedImage?.template) {
      if (activeFrame && activeFrame?.id !== selectedImage?.template?.id) {
        return false;
      }
      if (templateId === selectedImage?.template?.id) {
        return true;
      }
    }
    return false;
  };
  return (
    <div className="px-[36px] max-md:px-[18px] mb-[16px] flex flex-col gap-[16px] mt-[24px]">
      {favouriteFrames && favouriteFrames.data.length > 0 && (
        <FramesGroup
          groupName="Favorites"
          favourites={favouriteFrames.data}
          templates={favouriteFrames.data}
        />
      )}
      {frameGroups?.data.map((item) => (
        <FramesGroup
          favourites={favouriteFrames?.data}
          key={item.id}
          dKey={item.id.toString()}
          groupName={item.name}
          templates={item.templates}
        />
      ))}
    </div>
  );
}

export default FramesList;
