"use client";

import React, { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import BrandSwitcher from "../newSidebar/BrandSwitcher";
import Navigation from "../newSidebar/Navigation";
import HelperCardWrapper from "../newSidebar/HelperCardWrapper";
import ActiveWorkspace from "../newSidebar/ActiveWorkspace";
// @ts-ignore
import logo from "../../assets/images/logos/stori-full.svg";
import SButton from "../../design-system/SButton";
import { useNavigate } from "react-router-dom";

export default function MobileDrawer({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}: {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (open: boolean) => void;
}) {
  const [isBrandSelectorOpen, setIsBrandSelectorOpen] = useState(false);
  const [isWorkspaceSelectorOpen, setIsWorkspaceSelectorOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <Dialog
      open={isMobileMenuOpen}
      onClose={setIsMobileMenuOpen}
      className="relative z-20"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-right left-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto w-[calc(100vw-100px)] max-w-md transform transition duration-500 ease-in-out data-[closed]:-translate-x-full sm:duration-700"
            >
              <div
                className={`flex h-full flex-col overflow-y-scroll bg-white pt-[24px] shadow-sm ${isBrandSelectorOpen || isWorkspaceSelectorOpen ? "bg-[rgba(0,0,0,0.04)]" : "bg-base-white"}`}
              >
                <div className="px-[24px]">
                  <div className="flex items-center justify-between">
                    <img
                      src={logo}
                      alt="logo"
                      onClick={() => {
                        navigate("/");
                        setIsMobileMenuOpen(false);
                      }}
                      className="h-[24px]"
                    />
                    <SButton
                      icon={faX}
                      type={"secondaryGray"}
                      onClick={() => setIsMobileMenuOpen(false)}
                    />
                  </div>
                </div>
                <div className="relative my-[16px] flex-1">
                  <BrandSwitcher
                    isBrandSelectorOpen={isBrandSelectorOpen}
                    setIsBrandSelectorOpen={setIsBrandSelectorOpen}
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                  />
                  <Navigation setIsMobileMenuOpen={setIsMobileMenuOpen} />
                </div>
                <ActiveWorkspace
                  isWorkspaceSelectorOpen={isWorkspaceSelectorOpen}
                  setIsWorkspaceSelectorOpen={setIsWorkspaceSelectorOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
