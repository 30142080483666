import React, { useEffect, useState } from "react";

import ExpandedImageModal from "../../../utils/ExpandedImageModal";
import { ICopilotMessage } from "../../../../utils/copilot-base/ICopilotBase";

function UserMessage({ msg }: { msg: ICopilotMessage }) {
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  return (
    <>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
        />
      )}
      {msg.content?.Type === "UserMessage" && (
        <div className="flex items-end flex-col">
          <div className="flex flex-col items-end gap-[6px]">
            <p className="text-sm-m text-gray-700">You</p>
            {msg.content?.Message && (
              <pre className="whitespace-pre-wrap break-words max-w-[390px] w-full text-md max-md:text-sm text-gray-900 border border-gray-200 rounded-[8px] rounded-tr-none py-[10px] px-[14px] bg-gray-50">
                {msg.content?.Message}
              </pre>
            )}
          </div>
          {msg.files && (
            <div
              className={`
        grid 
        ${msg.files?.length === 1 && "grid-cols-1"}
        ${msg.files?.length === 2 && "grid-cols-2"}
        ${msg.files?.length > 2 && "grid-cols-3"}
         gap-[6px] mt-[12px]
        `}
            >
              {msg.files.map((item) => (
                <img
                  onClick={() => setExpandedImage(item.url)}
                  className="w-[80px] h-[80px] rounded-[6px] border border-gray-200 cursor-pointer shadow-xs object-contain"
                  src={item.thumbnailSmallUrl || item.url}
                  alt="image"
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default UserMessage;
