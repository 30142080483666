import { useState } from "react";
import TableTrItemChannel from "./TableTrItemChannel";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../design-system/SButton";
import { PostStatus, SocialNetworkType } from "../../../../utils/enums/Enums";
import { ISocialNetworkVM } from "../../../../utils/interfaces/ISocialNetworks";
import { useChannelsHelper } from "./functions/useChannelsHelper";
import { useCreationStore } from "../../../../store/creationStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Tooltip } from "react-tooltip";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import RemoveScheduleAgreement from "../../modals/RemoveScheduleAgreement";

interface ITableTrItem {
  hasBackground: boolean;
  index: number;
  image: string;
  title: string;
  socialNetworkType: SocialNetworkType;
  website: string;
  pages: ISocialNetworkVM[];
}

function TableTrItem({
  hasBackground,
  image,
  title,
  index,
  socialNetworkType,
  pages,
}: ITableTrItem) {
  const {
    selectedSocialNetworks,
    postData,
    whichSocialNetworkTypeConnectIsLoading,
  } = useCreationStore();
  const { handleSelect, handleConnect } = useChannelsHelper();
  const { getActiveSocialNetworkPost, getSocialNetworkNameByType } =
    useCreationHelperFunctions();
  const [
    isRemoveScheduleAgreementModalOpen,
    setIsRemoveScheduleAgreementModalOpen,
  ] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const socMedias = [
    {
      icon: faXTwitter,
      color: "#000000",
      type: SocialNetworkType.TWITTER,
    },
    {
      icon: faLinkedin,
      color: "#0077B5",
      type: SocialNetworkType.LINKEDIN,
    },
    {
      icon: faInstagram,
      color: "#E1306C",
      type: SocialNetworkType.INSTAGRAM,
    },
    {
      icon: faPinterest,
      color: "#E60023",
      type: SocialNetworkType.PINTEREST,
    },
    {
      icon: faFacebook,
      color: "#0866FF",
      type: SocialNetworkType.FACEBOOK,
    },
  ];
  const viewURL = (item: ISocialNetworkVM) => {
    return postData?.socialNetworkPosts.find(
      (item2) => item2.socialNetworkType === item.socialNetworkType
    )!.postUrl;
  };

  return (
    <tr
      className={`${hasBackground ? "bg-gray-50" : "bg-base-white"} text-left relative ${index !== 4 && "border-b-[1px]"} border-b-gray-200`}
    >
      <RemoveScheduleAgreement
        isOpen={isRemoveScheduleAgreementModalOpen}
        id={id}
        setIsOpen={setIsRemoveScheduleAgreementModalOpen}
      />
      <td className="px-[24px] py-[18px] flex gap-[12px] items-center">
        <FontAwesomeIcon
          icon={
            socMedias.filter((item) => item.type === socialNetworkType)[0].icon
          }
          color={
            socMedias.filter((item) => item.type === socialNetworkType)[0].color
          }
          className="w-[38px] h-[38px]"
        />
        <div className="h-full flex flex-col justify-center">
          <p className="text-sm-m text-gray-900">{title}</p>
        </div>
      </td>
      <td className="px-[24px] py-[16px]">
        <div className="flex flex-wrap gap-x-[16px] gap-y-[12px]">
          {postData &&
            pages.map((item) => {
              const sPostItem = postData.socialNetworkPosts.find(
                (sPost) => sPost.socialNetworkType === item.socialNetworkType
              );

              return (
                <>
                  <Tooltip id={`disabled-channel-${item.id}`}>
                    Post on this network is already{" "}
                    {sPostItem?.postStatus === PostStatus.PUBLISHED
                      ? "published"
                      : "scheduled"}
                  </Tooltip>

                  <TableTrItemChannel
                    dataTooltipId={
                      sPostItem?.postStatus === PostStatus.PUBLISHED ||
                      sPostItem?.postStatus === PostStatus.SCHEDULED
                        ? `disabled-channel-${item.id}`
                        : undefined
                    }
                    page={item}
                    handleSelect={handleSelect}
                    isDisabled={
                      sPostItem?.postStatus === PostStatus.PUBLISHED ||
                      sPostItem?.postStatus === PostStatus.SCHEDULED
                    }
                    status={
                      postData.socialNetworkPosts.find(
                        (sPost) =>
                          sPost.socialNetworkType === item.socialNetworkType
                      )!.postStatus
                    }
                    isChecked={selectedSocialNetworks.some(
                      (item2) => item2.id === item.id
                    )}
                  />
                  {sPostItem?.postStatus === PostStatus.PUBLISHED && (
                    <SButton
                      onClick={() => {
                        window.open(viewURL(item) as string, "_blank");
                      }}
                      type="secondaryColor"
                      className="w-fit py-[22px]"
                    >
                      View
                    </SButton>
                  )}
                  {sPostItem?.postStatus === PostStatus.SCHEDULED && (
                    <SButton
                      onClick={() => {
                        setIsRemoveScheduleAgreementModalOpen(true);
                        setId(sPostItem.id);
                      }}
                      type="secondaryColor"
                      className="w-fit py-[22px]"
                    >
                      Remove schedule
                    </SButton>
                  )}
                </>
              );
            })}
          {pages.length === 0 && (
            <SButton
              isLoading={
                whichSocialNetworkTypeConnectIsLoading === socialNetworkType
              }
              onClick={() => {
                if (!whichSocialNetworkTypeConnectIsLoading)
                  handleConnect(socialNetworkType);
              }}
              lIcon={faAdd}
            >
              Connect
            </SButton>
          )}
        </div>
      </td>
    </tr>
  );
}

export default TableTrItem;
