import React, { useEffect, useState } from "react";
import { useGetCurrentBrand } from "../api/brand/current/get";
import NeedBrandPageView from "../components/utils/NeedBrandPageView";
//@ts-ignore
import dashboardImage from "../assets/images/needBrandInfo/Dashboard.png";
//@ts-ignore
import assetsImage from "../assets/images/needBrandInfo/Assets.png";
//@ts-ignore
import brandImage from "../assets/images/needBrandInfo/Brand.png";
//@ts-ignore
import calendarImage from "../assets/images/needBrandInfo/Calendar.png";
//@ts-ignore
import connectionsImage from "../assets/images/needBrandInfo/Connections.png";
//@ts-ignore
import contentImage from "../assets/images/needBrandInfo/Content.png";
import { getQuery } from "../hooks/functions/queries";
import { useJoinWithGLobalLink } from "../api/invitations/invitationLink/post";
import { InviteModal } from "../components/dashboard/modals/InviteModal";
import { GlobalInviteMessageModal } from "../components/dashboard/modals/GlobalInviteMessageModal";
import { toast } from "../hooks/toast";
import { usePutSwitchTeam } from "../api/workspaces/swich/put";
function NeedBrandPageProvider({
  children,
  page,
}: {
  page:
    | "dashboard"
    | "brand"
    | "assets"
    | "content"
    | "calendar"
    | "connections";
  children: React.ReactNode;
}) {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const { data: currentBrand, isLoading: isLoading } = useGetCurrentBrand();
  useEffect(() => {
    const handleLoad = () => {
      setIsPageLoaded(true);
    };
    if (document.readyState === "complete") {
      setIsPageLoaded(true);
    } else {
      window.addEventListener("load", handleLoad);
    }
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  const code = getQuery("code");
  const isGlobal = getQuery("isGlobal");
  const [inviteModal, setInviteModal] = useState(
    code && !isGlobal ? true : false
  );
  const [globalInviteModal, setGlobalInviteModal] = useState(
    code && isGlobal ? true : false
  );
  const { mutateAsync: joinWorkspace } = useJoinWithGLobalLink();
  const { mutateAsync: switchWorkspace } = usePutSwitchTeam();
  useEffect(() => {
    const join = () => {
      joinWorkspace({ globalInvitationCode: code })
        .then((res) => {
          const id: number = parseInt(getQuery("workspaceId") || "0");
          switchWorkspace({ teamId: id }).then((res) =>
            setGlobalInviteModal(true)
          );
        })
        .catch((err) => {
          toast(err.message, "error");
        });
    };
    if (code && isGlobal) {
      join();
    }
  }, []);
  return (
    <>
      {inviteModal && (
        <InviteModal
          inviteModal={inviteModal}
          setInviteModal={setInviteModal}
        />
      )}
      {globalInviteModal && (
        <GlobalInviteMessageModal
          inviteModal={globalInviteModal}
          setInviteModal={setGlobalInviteModal}
        />
      )}
      {!isLoading ? (
        currentBrand ? (
          children
        ) : (
          <>
            {page === "dashboard" && (
              <NeedBrandPageView
                image={dashboardImage}
                text="Manage your brand, see all the posts - drafted, scheduled and published. Monitor activities, connected channels and team members with ease.."
                title="One Dashboard To Rule Them All"
              />
            )}
            {page === "brand" && (
              <NeedBrandPageView
                image={brandImage}
                text="Brand is the cornerstone for your future communications. All your ideas, posts and campaigns take inspiration from your brand. Generate and edit your brand from scratch, or a simple URL."
                title="It All Starts With Your Brand"
              />
            )}
            {page === "assets" && (
              <NeedBrandPageView
                image={assetsImage}
                text="Use Assets to upload, store and enhance your content with your own captured moments, documents and notes.."
                title="Assets to store all your ideas"
              />
            )}
            {page === "content" && (
              <NeedBrandPageView
                image={contentImage}
                text="Create, generate, upload, modify and share all your ideas."
                title="Anything and everything for your content"
              />
            )}
            {page === "calendar" && (
              <NeedBrandPageView
                image={calendarImage}
                text="Plan, schedule and manage posts effortlessly for all your connected media channels."
                title="Your Soc.Media Timelines - combined"
              />
            )}
            {page === "connections" && (
              <NeedBrandPageView
                image={connectionsImage}
                text="Link your Twitter(x), Linkedin, Instagram, Facebook, Reddit to STORI to connect with your audience."
                title="Add your channels to get started"
              />
            )}
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default NeedBrandPageProvider;
