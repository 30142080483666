import React from "react";
import { ISocialNetworkPostFileVM } from "../../../../utils/interfaces/IPost";

function FrameViewItem({
  file,
  className,
  isActive = false,
}: {
  className?: string;
  file?: ISocialNetworkPostFileVM;
  isActive?: boolean;
}) {
  return (
    <div
      className={`${className} ${isActive && "border-[2px] border-brand-600 rounded-[18px] p-[4px]"} p-[4px] border-0 `}
    >
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.40)",
          backdropFilter: "blur(3px)",
        }}
        className={` w-full h-full rounded-[12px] flex justify-center items-center overflow-hidden`}
      >
        {file?.templateOutputUrl ? (
          <div className="w-full h-full relative">
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50" />
            <p className="line-clamp-3 text-xs-sb text-base-white text-center px-[12px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">
              {file?.template?.name || "No frame"}
            </p>
            <img
              src={file?.template?.thumbnail}
              className="w-full h-full object-cover"
              alt=""
            />
          </div>
        ) : (
          <p className="text-sm-sb text-base-white text-center">No frame</p>
        )}
      </div>
    </div>
  );
}

export default FrameViewItem;
